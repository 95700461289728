import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import BrandPage from "./pages/BrandPage";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import { theme } from "./themes/theme";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./utils/translation/en";
import french from "./utils/translation/fr";
import chinese from "./utils/translation/cn";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import { languages } from "./utils/constants";

const {en, lang} = languages;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: english },
      fr: { translation: french },
      cn: { translation: chinese },
    },
    lng:JSON.parse(localStorage.getItem(lang))||en,
    fallbackLng: en,
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/brand" element={<BrandPage />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/product/:id" element={<ProductDetailsPage />} />
          </Routes>
        </div>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
