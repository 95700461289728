import { Box, Grid } from "@mui/material";
import ProductItem from "../components/ProductItem";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ProductPage = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <Box sx={{ flexGrow: 1, marginTop: 5 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 9, md: 12 }}
        justifyContent={'center'}
      >
        {JSON.parse(t("productsPage.productDetails")).map((item, index) => (
          <Grid key={index} item>
            <ProductItem
              name={item.name}
              price={item.price}
              subTitle={item.subTitle}
              images={item.images}
              onClickProduct={() => {
                navigate("/product/" + item.id);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductPage;
