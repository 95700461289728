import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Stack } from "@mui/material";

export default function DistributorCard(props) {
  const { title, image, url } = props;

  return (
    <Stack alignItems="center">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Card sx={{ maxWidth: 50 }}>
          <CardMedia component="img" height="50" image={image} alt={title} />
        </Card>
      </a>
      <p style={styles.partnerName}>{title}</p>
    </Stack>
  );
}

const styles = {
  partnerName: {
    textAlign: "center",
  },
};
