export const soapProductionStepsCn = [
  {
    label: "1. 烹煮”",
    description: `将植物油、苏打水、水和海盐放入大锅炉中。 在100 - 110度的温度下煮一周，烹煮的过程可以把苏打消除`,
  },
  {
    label: "2. 皂液灌入凝固盆",
    description:
      "当大锅炉里只剩下皂体时，将其倒入一个称为“凝固盆”的容器中。此时皂体以液态形态倒入此处，它将流入盆中并沉淀，凝固",
  },
  {
    label: "3. 切割",
    description: `我们将凝固后的皂体切成大块，每块重约50公斤，然后我们把这些大块再切成成小的立方体`,
  },
  {
    label: "4. 晾干",
    description: `将其在露天或通风的木架上晾干4-5天，并需要时不时的翻一下，以确保六个面都正确干燥`,
  },
  {
    label: "5. 印章",
    description: `我们将马赛皂一块一块的盖章，我们保证成品含有72％植物油且皂体不会灼伤皮肤。制作一块马赛皂大约需要21天。`,
  },
];

export const soapMarseillaisCn = [
  {
    label: "I. 皂化反应",
    description: `通过混合基本成分来制备皂体，将植物油在大锅中加热，在苏打和热的作用下，它们逐渐转变成肥皂。
    `,
  },
  {
    label: "II. 盐析",
    description:
      "皂体不溶于盐水，该操作包括添加海盐，以便将多余的苏打碱液吸到大锅底部。苏打水会“自行释放”，也就是说，它会下降到锅底， 并使皂体上升到表面。",
  },
  {
    label: "III. 烹煮",
    description: `通过添加浓缩苏打碱液，可以将植物油完全转化为肥皂。皂体要慢慢煮几天。正是在这一过程制作了马赛皂，皂体一旦变干，将保持坚硬并不易融化。现代技术可以使这一步骤加快很多，但要符合马赛过程就需要如此严格， 因为如果短短几个小时，皂体将没有时间完美地成型，那么皂的质量就会较低且难以保存。这一基本操作是皂化的特征，并解释了马赛皂的“众所周知的长寿”。
    当氢氧化钠碱性物质完全消失，大锅中只剩下皂体时烹饪结束。然后，马赛皂制造商大师会用舌尖品尝一下皂体，检查皂团的外观及其弹性。这是一种在书本上无法学到的艺术和专有技术。`,
  },
  {
    label: "IV. 清洗",
    description: `皂团经水洗精制而成，除去甘油、杂质和不皂化脂肪酸。这样我们获得干净纯净的马赛皂，因此我们在马赛皂上刻有铭文"Extra pur"，静置2天。然后执行最终粘度检查。`,
  },
  {
    label: "V. 肃清",
    description: `最后用清水清洗，使皂体达到最终状态。然后浮出光滑纯净的马赛皂，使马赛工艺享有盛誉。`,
  },
  {
    label: "",
    description: "这些不同的操作大约需要一周多时间。"
  },
]