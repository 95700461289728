import CardMedia from "@mui/material/CardMedia";
import { Card, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ValueCard = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12} md={4}>
        <Card variant="outlined">
          <CardMedia
            component="img"
            alt={props.name}
            height="100"
            sx={{
              objectFit: "contain",
              padding: "1em 1em 0 1em",
            }}
            image={props.image}
          />
          <Typography sx={{ fontSize: 18 }} align="center" gutterBottom>
            {t(props.text)}
          </Typography>
        </Card>
      </Grid>
    </>
  );
};

export default ValueCard;
