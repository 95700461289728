export const getRamdomProducts = (productData, num, relatedId) => {
  const productKey = relatedId.split("-")[1];
  const relatedAllProducts = productData.filter(
    (p) => p.id.split("-")[1] === productKey
  );
  if (relatedAllProducts.length <= num) {
    return relatedAllProducts;
  }
  return relatedAllProducts.sort(() => Math.random() - 0.5).slice(0, num);
};
