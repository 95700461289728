import { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { soapProductionStepsFr } from "../../data/fr/soapProcess_fr";
import { styled } from "@mui/material/styles";
import { Images } from "../../themes/common";
import { ImageCard } from "../UI/ImageCard";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

const SoapProcess = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "white" : "white",
    zIndex: 1,
    color: "#fff",
    width: 180,
    height: 180,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <ImageCard image={Images.soapCook} />,
      2: <ImageCard image={Images.soapFlow} />,
      3: <ImageCard image={Images.soapCut} />,
      4: <ImageCard image={Images.soapDry} />,
      5: <ImageCard image={Images.soapStamp} />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  return (
    <Grid container item xs={12} lg={8} sx={{ marginTop: 10 }}>
      <Typography gutterBottom variant="h4" textAlign={"center"}>
        {t("homePage.soapProductionProcess")}
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {JSON.parse(t("homePage.soapProductionProcessDetails")).map(
          (step, index) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ fontSize: 25 }}>{step.label}</Typography>      
              </StepLabel>
              <StepContent>
                <Typography
                  style={{ fontSize: 20, marginLeft: 150, marginRight: 20 }}
                >
                  {step.description}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1, marginLeft: 19 }}
                    >
                      {index === soapProductionStepsFr.length - 1
                        ? t("web.finish")
                        : t("web.next")}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t("web.back")}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          )
        )}
      </Stepper>
      {activeStep === soapProductionStepsFr.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>{t("homePage.finishedProcess")}</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            {t("web.reset")}
          </Button>
        </Paper>
      )}
    </Grid>
  );
};

export default SoapProcess;
