export const text = {
  about: `Nous sommes spécialisée dans l'exportation et la distribution de produits français en Chine et en Asie.`,
  activity: `Notre boutique a pour objectif de rassembler le Made in France en Chine. 
  Elle rassemble plusieurs marques de qualités. Elle reflète la qualité attendue du Made in France.
  Si vous voulez y présenter vos produits gratuitement contactez-nous.`,
  shopTitle: "Notre boutique en chine",
  companyName: "PHIOU SAS",
  contact: "7 allée des Glycines, 92500 Rueil-Malmaison",
  email: "contact@phiou.com",
  distributionTitle:
    "Nous nous appuyons sur un réseau de distributions en ligne et hors ligne",
};

export const languages = {
  fr: 'fr',
  en: 'en',
  cn: 'cn',
  lang: 'lang'
}