import { brandIntroCn } from "../../data/cn/brand_cn";
import { distributorsCn } from "../../data/cn/distributors_cn";
import { productDataCn } from "../../data/cn/product_data_cn";
import {
  lavenderSoapIntroCn,
  marseilleSoapIntro1Cn,
  marseilleSoapIntro2Cn,
} from "../../data/cn/products_cn";
import { soapMarseillaisCn, soapProductionStepsCn } from "../../data/cn/soapProcess_cn";

const chinese = {
  web: {
    back: "返回",
    next: "下一步",
    finish: "结束",
    reset: "重新开始",
    madeInFrance: "法国制造",
  },
  header: {
    welcome: "主页",
    brand: "品牌",
    products: "产品",
    websiteCn: "中文网站",
    chinese: "中文",
    english: "English",
    french: "Français",
  },
  homePage: {
    knowMore: "查看更多",
    lavenderSoap: "普罗旺斯薰衣草皂72%植物油",
    lavenderSoapIntro: lavenderSoapIntroCn,
    marseilleSoapTitle: "马赛皂72%植物油",
    marseilleSoapIntro1: marseilleSoapIntro1Cn,
    marseilleSoapSubTitle: "马赛皂的使用",
    marseilleSoapIntro2: marseilleSoapIntro2Cn,
    soapMarseillais: "马赛过程",
    soapMarseillaisDetails: JSON.stringify(soapMarseillaisCn),
    soapProductionProcess: "马赛皂的生产过程",
    soapProductionProcessDetails: JSON.stringify(soapProductionStepsCn),
    finishedProcess:"恭喜 🎉🎉 !! 你已经浏览完毕马赛皂的全部生产过程",
    ourShop: "我们在中国的店铺",
    ourPartners:
      "我们的合作伙伴",
    partnerList: JSON.stringify(distributorsCn),
    about: `Nous sommes spécialisée dans l'exportation et la distribution de produits français en Chine et en Asie.`,
    activity: `Notre boutique a pour objectif de rassembler le Made in France en Chine. 
    Elle rassemble plusieurs marques de qualités. Elle reflète la qualité attendue du Made in France.
    Si vous voulez y présenter vos produits gratuitement contactez-nous.`,
    companyName: "PHIOU SAS",
    address: "7 allée des Glycines, 92500 Rueil-Malmaison",
    email: "contact@phiou.com",
    distributionTitle:
      "我们的合作伙伴",
  },
  brandPage: {
    title: "PHIOU品牌",
    sales: "法国传统工艺",
    distributionChannel: "线上线下分销",
    vegan: "纯素",
    natural: "100%天然",
    marseille: "法国马赛生产",
    ingredient: "优质原料，健康呵护",
    history: "12世纪历史产物",
    brandIntro: JSON.stringify(brandIntroCn),
    historyTitle: "我们的故事",
    historyContent:
      "我们成立于2018， 在PHIOU，当时只有一个目标：拉近法国制造商与中国消费者的距离。在测试了中国市场之后，我们逐渐决定专注于护肤产品，尤其是马赛皂。在慢慢地熟悉马赛皂的独特与福祉，并且对我们身体健康的各种好处之后，我们开始创造一系列法国马赛皂产品。今天，我们致力于宣传马赛皂的，并把它分享给所有人。",
    valuesTitle: "我们的价值观",
    qualityValue:
      "质量，产品对每个人都有好处，不含不利成分.",
    transparencyValue:
      "透明度，提供有关我们产品的尽可能多的信息。",
    objectiveTitle: "我们的目标",
    objectiveContent:
      "我们力求成为中国法国马赛皂销售的领导者。",
  },
  productsPage: {
    description: "产品详情",
    ingredient: "成分",
    usageTips: "使用建议",
    relatedProducts: "相关产品",
    productDetails: JSON.stringify(productDataCn),
    convervation: "保存",
  },
};

export default chinese;
