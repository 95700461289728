import { brandIntroEn } from "../../data/en/brand_en";
import { distributorsEn } from "../../data/en/distributors_en";
import { productDataEn } from "../../data/en/product_data_en";
import {
  lavenderSoapIntroEn,
  marseilleSoapIntro1En,
  marseilleSoapIntro2En,
} from "../../data/en/products_en";
import { soapProductionStepsEn, soapMarseillaisEn } from "../../data/en/soapProcess_en";

const english = {
  web: {
    back: "Back",
    next: "Next",
    finish: "Finish",
    reset: "Restart",
    madeInFrance: "Made in France",
  },
  header: {
    welcome: "Home",
    brand: "Brand",
    products: "Products",
    websiteCn: "中文网站",
    chinese: "中文",
    english: "English",
    french: "Français",
  },
  homePage: {
    knowMore: "Know more",
    lavenderSoap: "Provence soap with lavender 72% vegetable oil",
    lavenderSoapIntro: lavenderSoapIntroEn,
    marseilleSoapTitle: "Marseille soap 72% vegetable oil",
    marseilleSoapIntro1: marseilleSoapIntro1En,
    marseilleSoapSubTitle: "Use of Marseille soap",
    marseilleSoapIntro2: marseilleSoapIntro2En,
    soapMarseillais: "The Marseille process",
    soapMarseillaisDetails: JSON.stringify(soapMarseillaisEn),
    soapProductionProcess: "The production of our Marseille soaps",
    soapProductionProcessDetails: JSON.stringify(soapProductionStepsEn),
    ourShop: "Our shop in China",
    partnerList: JSON.stringify(distributorsEn),
    companyName: "PHIOU SAS",
    address: "7 allée des Glycines, 92500 Rueil-Malmaison",
    email: "contact@phiou.com",
    distributionTitle:
      "Our distribution partners",
  },
  brandPage: {
    title: "PHIOU",
    sales: "French traditional crafts",
    distributionChannel: "Online and offline distribution",
    vegan: "100% Vegan",
    natural: "100% natural",
    marseille: "Made in Marseille, France",
    ingredient: "Quality ingredients for your health",
    history: "A historical product of the 12th century",
    brandIntro: JSON.stringify(brandIntroEn),
    historyTitle: "Our history",
    historyContent:
      "We created PHIOU in 2018 with a single objective, that of bringing French manufacturers closer to Chinese consumers. We gradually decided to specialize in beauty and care products, in particular Marseille soaps. We have therefore created a range of Marseille soaps, made in France, with natural ingredients and essential oils. Today, we seek to promote the benefits of Marseille soaps and to make them accessible in China.",
    valuesTitle: "Our values",
    qualityValue:
      "Quality, with products that are good for everyone, without harmful ingredients.",
    transparencyValue:
      "Transparency, with a maximum of information available on our products.",
    objectiveTitle: "Our objective",
    objectiveContent:
      "We seek to become the leader in the sale of French soaps in China.",
  },
  productsPage: {
    description: "Product details",
    ingredient: "Ingredients",
    usageTips: "Usage tips",
    relatedProducts: "Related products",
    productDetails: JSON.stringify(productDataEn),
    convervation: "Conversation",
  },
};

export default english;
