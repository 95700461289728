import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../utils/constants";
import i18next from "i18next";

const Context = createContext();

export const Provider = ({ children }) => {
  const {t} = useTranslation()
  const {lang: lng, en} = languages;
  const [lang, setLang] = useState(JSON.parse(localStorage.getItem(lng)) || en);
  const changeLanguage = (language) => {
    setLang(language);
    localStorage.setItem(lng, JSON.stringify(language));
    i18next.changeLanguage(language)
  };

  const getProduct = (id) => {
    return JSON.parse(t("productsPage.productDetails")).find((product) => product.id === id);
  };
  return <Context.Provider value={{ getProduct, lang, changeLanguage }}>{children}</Context.Provider>;
};

export default Context;
