import {
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import { Carousel } from "react-responsive-carousel";
import Context from "../context/store";
import { useTranslation } from "react-i18next";
import { getRamdomProducts } from "../utils/helpers";

const ProductDetailsPage = () => {
  const id = useParams().id;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getProduct } = useContext(Context);
  const product = getProduct(id);
  const images = product?.images;

  return (
    <Grid
      sx={{
        flexGrow: 1,
        marginTop: 5,
      }}
    >
      <Grid
        container
        alignContent={"center"}
        spacing={{ xs: 2, sm: 2 }}
        sx={{
          justifyContent: "center",     
        }}
      >
        <Grid item xs={12} md={5}>
          <Carousel autoPlay={true} swipeable={true} infiniteLoop={true}>
            {images.map((img)=>  <div key={img}>
              <img alt={`phiou product ${img}`} src={img} />
            </div>)}      
          </Carousel>   
        </Grid>
        <Grid item xs={12} md={5} sx={{marginLeft: {xs: 0, md: 10 }}}>
          <Typography variant="h3">{product.name}</Typography>
          <Typography variant="h4">{product.subTitle}</Typography>
          <Typography variant="h5">{product.price}</Typography>
          <Typography variant="h6" textAlign={"justify"}>{product.intro}</Typography>
          <Stack
            direction={"row"}
            style={{
              marginTop: 10,
              justifyContent: "flex-end",
            }}
          >
            {product.icons.map((icon) => (
              <div style={{ width: 70, margin: 10 }}>
                <CardMedia
                  component="img"
                  key={icon}
                  image={icon}
                  resizeMode="contain"
                  alt="icons product"
                />
              </div>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid item>
      <Typography textAlign="center" variant="h4"  sx={{mb: 2}}>
        {t("productsPage.description")}
      </Typography>
      
      <Typography textAlign="justify" dangerouslySetInnerHTML={{ __html: product.description }} />
        {/* <Typography>{product.description}</Typography> */}
        <Typography variant="h5">{t("productsPage.ingredient")}</Typography>
        <Typography textAlign="justify" sx={{mb: 2}}>{product.ingredient}</Typography>
        <Typography variant="h5">{t("productsPage.usageTips")}</Typography>
        <Typography textAlign="justify" sx={{mb: 2}}>{product.usageTips}</Typography>
        <Typography variant="h5">{t("productsPage.convervation")}</Typography>
        <Typography textAlign="justify" sx={{mb: 2}} dangerouslySetInnerHTML={{ __html: product.conservation }}/>
        <Typography variant="h5">
          {t("productsPage.relatedProducts")}
        </Typography>
        <Stack direction={'row'}>{getRamdomProducts(JSON.parse(t("productsPage.productDetails")), 4, product.id).map((item)=> {
          return (
            <Grid item xs={3} md={12}>
            <div style={{margin: 10, }} key={item.id}>
              <CardMedia
                component="img"
                key={item.id}
                height="80"
                image={item.images?.[0]}
                resizeMode="contain"
                alt="icons product"
                style={{borderRadius: 10}}
                onClick={()=> navigate(`/product/${item.id}`)}
              />
            </div>
            </Grid>
          )
        })}
        </Stack>
      </Grid>
    </Grid>
  ); 
};

export default ProductDetailsPage;
