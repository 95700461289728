import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Images } from "../../themes/common";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

const ProvenceSoapCard = ({onClickProvenceSoap}) => {
  const { t } = useTranslation();

  return (
      <Grid container sx={{marginTop: 5}}>
        <Grid item xs={12} lg={8}>
          <CardMedia
            component="img"
            alt="PHIOU lavender soap"
            sx={{
              marginRight: "1em",
              flexGrow: 1,
            }}
            image={Images.lavenderSoap}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
            <Typography gutterBottom variant="h4" sx={{mt: 2}} textAlign={"center"}>
              {t("homePage.lavenderSoap")}
            </Typography>
            <Typography
              variant="h5"
              color="text.secondary"
              textAlign={"justify"}
              sx={{ml: 3, mr: 3}}
            >
              {t("homePage.lavenderSoapIntro")}
            </Typography>
            <CardActions style={styles.actionContainer} >
              <Button size="small" variant="contained" onClick={onClickProvenceSoap}>
                {t("homePage.knowMore")}
              </Button>
            </CardActions>
        </Grid>
      </Grid>
  );
};

const styles = {
  actionContainer: {
    marginTop: 10,
    marginLeft: 20,
  },
};
export default ProvenceSoapCard;
