import { CardMedia } from "@mui/material";

export const ImageCard = ({ image }) => {
  return (
    <CardMedia
      component="img"
      alt="green iguana"
      sx={{
        // marginRight: 20,
        // background: "red",
        // marginTop: "3em",
        objectFit: "contain",
      }}
      image={image}
    />
  );
};
