export const soapProductionStepsEn = [
  {
    label: "1. Cooking",
    description: `We put vegetable oil, soda, water and sea salt in the cauldron. Bake for one week at 100 - 110 degrees. Cooking eliminates the soda.`,
  },
  {
    label: "2. Pouring",
    description:
      "When there is nothing left but soap in the cauldron, it is poured into a basin called the bet. This is where the soap will be poured in its liquid state. It will flow into the basin, then settle and solidify.",
  },
  {
    label: "3. Cutting",
    description: `We cut into large blocks. Each block weighs approximately 50 kilos. We will reduce these blocks into small cubes.`,
  },
  {
    label: "4. Drying",
    description: `We dry it in the open air or on a ventilated wooden rack for 4-5 days and need to turn it over now and then to make sure all six sides are dry properly.`,
  },
  {
    label: "5. Stamping",
    description: `We stamp. At the exit, we have a cube with 72% oil and which does not burn the skin. It takes 21 days to make a cube of Marseille soap.`,
  },
];

export const soapMarseillaisEn = [
  {
    label: "I. Reaction of saponification",
    description: `The first step is to prepare the soap paste by mixing the basic ingredients, The oils of vegetable origin are heated in a large cauldron. Under the action of soda and heat, they gradually turn into soap.
    `,
  },
  {
    label: "II. The release",
    description:
      'The soap being insoluble in salt water, this operation consists of adding sea salt in order to draw the excess soda lye to the bottom of the cauldron. The soda "releases itself", that is to say that it descends to the bottom of the tub, the soap remaining on top.',
  },
  {
    label: "III. Cooking",
    description: `Vegetable oils can be completely converted to soap by adding concentrated soda lye. The soap body should be boiled slowly for a few days. It is during this process that Marseille soap is made, which, once dry, remains firm and does not melt easily. Modern technology can make this step much faster, but it needs to be so rigorous to comply with the Marseille process, because if it is just a few hours, the soap body will not have time to form perfectly, and the soap will be of lower quality and difficult to preserve. This fundamental operation is characteristic of saponification and explains the "well-known longevity" of Marseille soap.
    Cooking ends when the sodium hydroxide alkalinity has completely disappeared and only soap remains in the cauldron. The master Marseille soap maker then tastes the soap with the tip of his tongue to check the appearance of the dough and its elasticity. It's an art and know-how that can't be learned in books.`,
  },
  {
    label: "IV. Washing",
    description: `The soap mass is refined by washing with water to remove glycerin, impurities and unsaponifiable fatty acids. This way we get clean and pure Marseille soap, so we put the inscription "Extra pur" on the Marseille soap and let it sit for 2 days. Then perform a final viscosity check.`,
  },
  {
    label: "V. The liquidation",
    description: `A final wash with clear water brings the soap to its final state. Then floats the smooth and pure soap that makes the reputation of the Marseille process.`,
  },
  {
    label: "",
    description: "These different operations take about over a week."
  },
]