import { brandIntroFr } from "../../data/fr/brand_fr";
import { distributorsFr } from "../../data/fr/distributors_fr";
import { productDataFr } from "../../data/fr/product_data_fr";
import {
  lavenderSoapIntroFr,
  marseilleSoapIntro1Fr,
  marseilleSoapIntro2Fr,
} from "../../data/fr/products_fr";
import { soapProductionStepsFr, soapMarseillaisFr } from "../../data/fr/soapProcess_fr";

const french = {
  web: {
    back: "Retour",
    next: "Suivant",
    finish: "Finir",
    reset: "Recommencer",
    madeInFrance: "Fabriqué en France",
  },
  header: {
    welcome: "Accueil",
    brand: "La marque",
    products: "Les produits",
    websiteCn: "中文网站",
    chinese: "中文",
    english: "English",
    french: "Français",
  },
  homePage: {
    knowMore: "En savoir plus",
    lavenderSoap: "Savon de Provence à la lavande 72% d'huile",
    lavenderSoapIntro: lavenderSoapIntroFr,
    marseilleSoapTitle: "Savon de Marseille 72% d'huile",
    marseilleSoapIntro1: marseilleSoapIntro1Fr,
    marseilleSoapSubTitle: "Utilisation du savon de Marseille",
    marseilleSoapIntro2: marseilleSoapIntro2Fr,
    soapMarseillais: "Le procédé de fabrication Marseillais",
    soapMarseillaisDetails: JSON.stringify(soapMarseillaisFr),
    soapProductionProcess: "La fabrication de nos Savons de Marseille",
    soapProductionProcessDetails: JSON.stringify(soapProductionStepsFr),
    finishedProcess:"Felicitation 🎉🎉 !! Vous avez bien lu le processus de fabrication du savon de Marseille",
    ourShop: "Notre boutique en chine",
    ourPartners:
      "Nous nous appuyons sur un réseau de distributions en ligne et hors ligne",
    partnerList: JSON.stringify(distributorsFr),
    about: `Nous sommes spécialisée dans l'exportation et la distribution de produits français en Chine et en Asie.`,
    activity: `Notre boutique a pour objectif de rassembler le Made in France en Chine. 
    Elle rassemble plusieurs marques de qualités. Elle reflète la qualité attendue du Made in France.
    Si vous voulez y présenter vos produits gratuitement contactez-nous.`,
    companyName: "PHIOU SAS",
    address: "7 allée des Glycines, 92500 Rueil-Malmaison",
    email: "contact@phiou.com",
    distributionTitle:
      "Nos partenaires de distribution",
  },
  brandPage: {
    title: "La marque PHIOU",
    sales: "Artisanat traditionnel français",
    distributionChannel: "Distribution en ligne et hors ligne",
    vegan: "100% Vegan",
    natural: "100% naturel",
    marseille: "Fabriqué en France à Marseille",
    ingredient: "Des ingrédients de qualité pour votre santé",
    history: "Un produit historique du XIIème siècle",
    brandIntro: JSON.stringify(brandIntroFr),
    historyTitle: "Notre histoire",
    historyContent:
      "Nous avons créé PHIOU en 2018 avec un seul objectif, celui de rapprocher les fabricants français des consommateurs chinois. Nous avons peu à peu décidé de nous spécialiser dans les produits de beauté et de soin, en particulier les savons de Marseille. Nous avons donc créé une gamme de savons de Marseille, fabriqués en France, avec des ingrédients naturels et des huiles essentielles. Aujourd'hui, nous cherchons à faire connaitre les bienfaits des savons de Marseille et à les rendre accessibles en Chine.",
    valuesTitle: "Nos valeurs",
    qualityValue:
      "La qualité, avec des produits qui sont bons pour chacun, sans ingrédient nocif.",
    transparencyValue:
      "La transparence, avec un maximum d'informations disponible sur nos produits.",
    objectiveTitle: "Notre objectif",
    objectiveContent:
      "Nous cherchons à devenir le leader de la vente de savons français en Chine.",
  },
  productsPage: {
    description: "Détails du produit",
    ingredient: "Ingrédients",
    usageTips: "Conseils d’utilisation",
    relatedProducts: "Produits associés",
    productDetails: JSON.stringify(productDataFr),
    convervation: "Convervation",
  },
};

export default french;
