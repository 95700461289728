export const lavenderSoapIntroCn =
  "来自普罗旺斯的PHIOU薰衣草香皂是一款天然滋养、保湿和舒缓的产品。其丰富的油脂成分和迷人的香味使其成为护理肌肤和在日常清洁时享受片刻放松的热门选择。";

export const marseilleSoapIntro1Cn =`
PHIOU橄榄油马赛皂是马赛市的标志性传统产品。它主要由橄榄油， 其次还有海盐，水和苏打制成。 这些成分都是皂化作用是将油变成皂体的过程所需的必要成分。

生产是按照马赛皂传统工艺方法进行的。它遵循缓慢的烹煮和长时间的干燥过程，这需要几周的时间，以获得优质的马赛皂。`

export const marseilleSoapIntro2Cn = `
橄榄油马赛皂以其温和、滋养皮肤的特性而闻名。其成分中的橄榄油富含必需脂肪酸和维生素，有助于滋润和保护皮肤。它适合所有皮肤类型，包括敏感皮肤。

马赛皂用于清洗身体、手和脸。 它与水接触时会产生柔软的奶油状泡沫，便于涂抹和冲洗。它也可以用作洗发皂。
`;