import { Images } from "../../themes/common";

const perfumeSoapUsage = `Ce savon parfumé est idéal pour l'hygiène des mains et du corps, pour toute la famille ! Usage externe uniquement. 
Le produit est à utiliser dilué avec de l’eau et doit faire l’objet d’un rinçage soigneux.
Eviter le contact avec les yeux. En cas de projection dans les yeux, rincer à l'eau claire.`

const marseilleSoapUsage = `Ce savon de Marseille est idéal pour l'hygiène des mains, du corps et des cheveux, pour toute la famille ! Usage externe uniquement.
Le produit est à utiliser dilué avec de l’eau et doit faire l’objet d’un rinçage soigneux.
Eviter le contact avec les yeux. En cas de projection dans les yeux, rincer à l'eau claire.
`

const introCube = '150 g fabrication artisanale de ≈ 5.5x5.5x5 cm'
const descMarseilleSoapOlive = `
La méthode traditionnelle de fabrication du véritable Savon de Marseille consiste à cuire les ingrédients au chaudron à l'ancienne. Cette méthode ancestrale remonte à plusieurs siècles et est encore utilisée par notre fabricant aujourd'hui.

Grâce à ses propriétés hypoallergéniques et bactéricides, ce savon convient à tous les types de peau, il est aussi vivement recommandé pour les peaux hyper-sensibles et peut être utilisé pour bébé.

L’huile d’olive est connue pour apporter ses vertus nourrissantes à la peau et c'est pourquoi le savon de Marseille à l’huile d’olive est une excellente option pour ceux qui cherchent à diminuer le dessèchement de leur peau tout en profitant des bienfaits de cet ingrédient naturel. Sans colorant ni additif synthétique, il n'est pas dérivé de pétrole ou de graisses animales. Pur végétal, biodégradable, sans phosphates ni produits synthétiques, il ne pollue pas et contribue à la préservation de l'environnement. Il dure très longtemps et est particulièrement économique.

<p> Le savon de Marseille possède de multiples vertus et peut être utilisé pour :</p>
<ul><li> La toilette - prenez soin des peaux les plus sèches et les plus sensibles. </li>
<li>Soigner les plaies - le savon de Marseille nettoie les petites plaies et les écorchures.</li>
<li>Comme dentifrice - il est excellent pour les gencives et laisse les dents propres et blanches.</li>
<li>Comme détachant - une tâche frottée avant la lessive avec un savon légèrement humide sera plus efficacement enlevée qu'avec tous les détergents synthétiques (idéal pour les cols de chemise).</li>
<li>Contre les crampes - placez une tranche de savon de Marseille au fond du lit pour soulager les crampes et les rhumatismes.</li>
<li>Contre les mites - placez un savon de Marseille dans votre armoire pour éloigner les mites.</li>
</ul>
`
const descMarseilleSoapCoco = `La méthode traditionnelle de fabrication du véritable Savon de Marseille consiste à cuire les ingrédients au chaudron à l'ancienne. Cette méthode ancestrale remonte à plusieurs siècles et est encore utilisée par notre fabricant aujourd'hui.

Grâce à ses propriétés hypoallergéniques et bactéricides, ce savon convient à tous les types de peau.

Ce savon de Marseille blanc possède des vertus hypoallergéniques et bactéricides qui le rendent particulièrement recommandé par les dermatologues pour les peaux hyper-sensibles et peut être utilisé pour les bébés.

Le savon de Marseille contient des huiles végétales qui apportent des vertus nourrissantes et hydratantes grâce à la présence d'huile de coco. Il contribue à diminuer le dessèchement de la peau. L'huile de coco est connue pour ses bienfaits, car elle aide à retenir l'humidité et à maintenir la peau douce et souple. Elle peut également avoir des propriétés antibactériennes et apaisantes. Le savon de Marseille est pur végétal, biodégradable, sans phosphates ni produits de synthèse, il ne pollue pas et contribue à la préservation de l'environnement. En outre, il est très économique et dure très longtemps.
<p> Le savon de Marseille possède de multiples vertus et peut être utilisé pour :</p>
<ul><li> La toilette - prenez soin des peaux les plus sèches et les plus sensibles. </li>
<li>Soigner les plaies - le savon de Marseille nettoie les petites plaies et les écorchures.</li>
<li>Comme dentifrice - il est excellent pour les gencives et laisse les dents propres et blanches.</li>
<li>Comme détachant - une tâche frottée avant la lessive avec un savon légèrement humide sera plus efficacement enlevée qu'avec tous les détergents synthétiques (idéal pour les cols de chemise).</li>
<li>Contre les crampes - placez une tranche de savon de Marseille au fond du lit pour soulager les crampes et les rhumatismes.</li>
<li>Contre les mites - placez un savon de Marseille dans votre armoire pour éloigner les mites.</li>
</ul>
`

const conservation = `<ul><li>Gardez-le au sec : Après chaque utilisation, assurez-vous de bien sécher votre savon de Marseille et de le conserver dans un endroit sec. L'humidité excessive peut ramollir le savon et réduire sa durée de vie.</li>

<li>Utilisez un porte-savon : Placez votre savon de Marseille sur un porte-savon qui permet à l'excès d'eau de s'écouler. Cela aide à prévenir la formation de résidus et à maintenir le savon au sec entre les utilisations.</li>

<li>Évitez les sources de chaleur : Éloignez votre savon de Marseille des sources de chaleur directe, telles que les radiateurs</li>
</ul>`

export const productDataFr = [
  {
    id: "s1-o",
    name: "Savon de Marseille Huile d'Olive",
    price: "2.99€",
    subTitle: "100 g cube de ≈ 5x5x5 cm",
    intro: "Le savon de Marseille authentique, adapté pour la toilette corporelle et des mains, est élaboré selon des méthodes anciennes, sous la supervision d'un maître savonnier. Il est cuit à chaud avec 72% d'huile végétal et préparé avec de l'huile d'olive extra pure. Ce savon est exempt d'huile de palme, de colorants, de conservateurs et de parfums. Il est présenté sous forme d'un cube raffiné, mesurant environ 5x5x5 cm et pesant 100 grammes sur le savon frais, teneur principalement en huile d'olive.",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive100g,Images.soapOlive100g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },  
  {
    id: "s2-o",
    name: "Savon de Marseille Huile Végétal de Coco",
    price: "2.99€",
    subTitle: "100 g huile de coco cube de ≈ 5x5x5 cm",
    intro: "Le véritable savon de Marseille, conçu pour les peaux les plus délicates et sensibles. Chaque cube est cuit, pailleté, séché et repressé avec soin sous la supervision d'un maître savonnier, donnant ainsi un aspect net et lisse. Avec 72% d'huile végétale pure, riche en acides gras saturés bénéfiques pour la peau, il ne contient ni huile de palme, ni colorant, ni conservateur, ni parfum artificiel. Le cube pèse environ 100 grammes sur le savon frai et mesure 5x5x5 cm, parfait pour l'hygiène corporelle de tous les âges, des bébés aux adultes.",
    description: descMarseilleSoapCoco,
    images: [Images.soapCoco100g],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: perfumeSoapUsage,
    conservation: conservation
  },  
  {
    id: "s3-o",
    name: "Savon de Marseille 72% d'Huile",
    price: "4.49€",
    subTitle: "300 g huile d'olive cube de ≈ 6.5x6.5x6.5 cm",
    intro: "Le savon de Marseille authentique, adapté pour la toilette corporelle et des mains, est élaboré selon des méthodes anciennes, sous la supervision d'un maître savonnier. Il est cuit à chaud avec 72% d'huile végétal et préparé avec de l'huile d'olive extra pure. Ce savon est exempt d'huile de palme, de colorants, de conservateurs et de parfums. Il est présenté sous forme d'un cube raffiné, mesurant environ 6.5x6.5x6.5 cm et pesant 300 grammes sur le savon frais, teneur principalement en huile d'olive.",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive300g,Images.soapOlive300g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s4-o",
    name: "Savon de Marseille Huile d'Olive",
    price: "5.89€",
    subTitle: "400 g huile d'olive cube de ≈ 7.5x7.5x7 cm",
    intro: "Le savon de Marseille authentique, adapté pour la toilette corporelle et des mains, est élaboré selon des méthodes anciennes, sous la supervision d'un maître savonnier. Il est cuit à chaud avec 72% d'huile végétal et préparé avec de l'huile d'olive extra pure. Ce savon est exempt d'huile de palme, de colorants, de conservateurs et de parfums. Il est présenté sous forme d'un cube brut, mesurant environ 7.5x7.5x7 cm et pesant 400 grammes sur le savon frais, teneur principalement en huile d'olive.",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive400g, Images.soapOlive400g2, Images.soapOlive400g3, Images.soapOlive400g4, Images.soapOlive400g5],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s5-o",
    name: "Savon de Marseille Huile d'Olive",
    price: "8.49€",
    subTitle: "600 g huile d'olive cube de ≈ 8.5x8.5x8 cm",
    intro: "400 g huile d'olive cube de 8.5x8.5x8 cm environ",
    intro: "Le savon de Marseille authentique, adapté pour la toilette corporelle et des mains, est élaboré selon des méthodes anciennes, sous la supervision d'un maître savonnier. Il est cuit à chaud avec 72% d'huile végétal et préparé avec de l'huile d'olive extra pure. Ce savon est exempt d'huile de palme, de colorants, de conservateurs et de parfums. Il est présenté sous forme d'un cube brut, mesurant environ 8.5x8.5x8 cm et pesant 600 grammes sur le savon frais, teneur principalement en huile d'olive.",
    description:descMarseilleSoapOlive,
    images: [Images.soapOlive600g, Images.soapOlive600g2, Images.soapOlive600g3, Images.soapOlive600g4],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s6-o",
    name: "Savon de Marseille Huile d'Olive",
    price: "13.59€",
    subTitle: "1000 g cube of ≈ 10x9.8x9.8 cm",
    intro: "1000 g huile d'olive cube de 10x9.8x9.8 cm environ",
    intro: "Le savon de Marseille authentique, adapté pour la toilette corporelle et des mains, est élaboré selon des méthodes anciennes, sous la supervision d'un maître savonnier. Il est cuit à chaud avec 72% d'huile végétal et préparé avec de l'huile d'olive extra pure. Ce savon est exempt d'huile de palme, de colorants, de conservateurs et de parfums. Il est présenté sous forme d'un cube brut, mesurant environ 10x9.8x9.8 cm et pesant 1000 grammes sur le savon frais, teneur principalement en huile d'olive.",
    description:descMarseilleSoapOlive,
    images: [Images.soapOlive600g, Images.soapOlive600g2, Images.soapOlive600g3, Images.soapOlive600g4],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s6-p",
    name: "Savon de Provence à la lavande",
    price: "4.49€",
    subTitle: "200 g cube huile végétal cube de ≈ 6x6x5 cm",
    intro: "Notre savon de Provence PHIOU à la lavande est un produit traditionnelle, fabriqué à Marseille au sud de la France par des maîtres du Savon de Marseille. Il est réputé pour sa qualité et sa composition naturelle.",
    description: `<p>Ce savon de Provence à la lavande est composé de 72% d'huile. Cette huile est d'origine végétale. Il contient également des ingrédients naturels tel que des extraits de plantes, de l'huile essentielle de lavande et etc.
    </p>
    <p>
    Ce savon est apprécié pour ses propriétés nourrissantes et hydratantes. L'huile présente dans sa composition permet de préserver l'hydratation de la peau et de la rendre douce et souple. La lavande, quant à elle, possède des vertus apaisantes et relaxantes, idéales pour favoriser la détente et le bien-être.
    </p>
    <p>
    Le savon de Provence PHIOU à la lavande dégage un parfum délicat et envoûtant. L'huile essentielle de lavande utilisée dans sa fabrication lui confère cette senteur caractéristique qui rappelle les champs de lavande de Provence.</p>`,
    images: [Images.soapLavender200g,Images.soapLavender200g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium palmate, Sodium palm kernelate, Aqua, Palm kernel acid, Ricinus communis (castor) seed oil,  Sodium chloride, Glycerin, Lavandula hybrida oil, Tetrasodium edta, Tetrasodium etidronate, Lavandula angustifolia (lavender) oil, CI 73360, CI77007.",
    usageTips: perfumeSoapUsage,
    conservation: conservation
  },
  {
      id: "s7-p",
      name: "Savon de Marseille Vanille",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi de vanilla.  
      `,
      description: `
      <p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Propriétés hydratantes : La vanille contient des composés hydratants qui aident à maintenir l'hydratation naturelle de la peau. Lorsqu'elle est utilisée dans un savon de Marseille, elle peut contribuer à nourrir la peau et à prévenir la sécheresse, laissant ainsi une sensation de douceur et de souplesse.</p><p>

      Antioxydants : La vanille est riche en antioxydants naturels tels que la vanilline, qui aident à protéger la peau contre les radicaux libres et les dommages causés par les facteurs environnementaux. Ces antioxydants peuvent contribuer à maintenir une peau saine et à réduire les signes du vieillissement prématuré. Il assouplit la peau et éclaircit le teint.</p><p>
      Senteur naturelle : Odeur inimitable de la vanille, chaud et légèrement sucré et gourmand,  à la mousse légère et une agréable odeur qui sent les vacances. 
      </p><p>
      La vanille a considérée comme apaisante et relaxante. Le savon à la vanille confère un parfum doux et chaud et est un parfum populaire dans de nombreux produits de bain.</p>`,
      images: [Images.soapVanilla,Images.soapVanilla2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium olivate, Aqua, Sodium cocoate, Sodium palm kernelate, Parfum, Palm kernel acid, Sodium chloride, Glycerin, Sodium hydroxide, Tetrasodium etidronate, Coumarin.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s8-p",
      name: "Savon de Marseille Voilette",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi de voilette.`,
      description: `
      <p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Propriétés adoucissantes : Le savon de Marseille à la violette contient des ingrédients naturels qui aident à adoucir et à hydrater la peau. Il peut contribuer à maintenir l'équilibre hydrique de la peau, la laissant douce et souple.</p><p>

      Apaisement de la peau : La violette est réputée pour ses propriétés apaisantes et apaisantes pour la peau. Elle peut aider à calmer les irritations cutanées mineures, les rougeurs et les démangeaisons, offrant ainsi une sensation de confort.</p><p>

      Parfum durable : Le parfum délicat de la violette peut persister sur la peau après le rinçage, laissant une sensation de fraîcheur et de légèreté tout au long de la journée.</p>`,
      images: [Images.soapViolet,Images.soapViolet2, Images.soapViolet3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 42090, CI 17200, Alpha-isomethyl ionone, Hexyl cinnamal.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s9-p",
      name: "Savon de Marseille Fruit de la Passion",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum.`,
      description: `
      <p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Parfum exotique : Le fruit de la passion est connu pour son parfum tropical et envoûtant. L'utilisation d'un savon de Marseille au fruit de la passion peut apporter une fragrance agréable à votre routine de toilette, procurant une sensation de fraîcheur et d'évasion.</p><p>
      Antioxydants : Le fruit de la passion est riche en antioxydants tels que les vitamines A et C, qui aident à protéger la peau contre les radicaux libres et les dommages causés par l'environnement. Il détient un actif célèbre: la passiflore. Une mine de vitalité pour le corps et les cheveux. Bien que l'effet des antioxydants puisse varier selon la concentration dans le savon, leur présence peut contribuer à préserver la santé et l'éclat de la peau.</p><p>
      Hydratation: L'utilisation d'un savon de Marseille au fruit de la passion peut aider à maintenir l'hydratation naturelle de la peau, la laissant douce et souple.</p> 
      `,
      images: [Images.soapPassion,Images.soapPassion2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, Hexyl cinnamal, Limonene, CI 14700, CI 17200, CI 77499.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s10-p",
      name: "Savon de Marseille Citron Broyé",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi aux écorces de citron.`,
      description: `<p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Idéal pour un gommage et un adoucissement de la peau.</p><p>
      Purifiant et antiseptique : Le citron est reconnu pour ses propriétés purifiantes et antiseptiques. Le savon de Marseille au citron broyé peut aider à éliminer les impuretés et les bactéries présentes sur la peau, favorisant ainsi une sensation de propreté et de fraîcheur.</p><p>

      Éclaircissant et tonifiant : Le citron est également connu pour ses propriétés éclaircissantes et tonifiantes. Le savon de Marseille au citron broyé peut aider à uniformiser le teint et à donner à la peau un éclat naturel. Il peut également aider à réduire l'apparence des taches et des imperfections cutanées.</p><p>

      Rafraîchissant : L'arôme frais et citronné du savon de Marseille au citron broyé offre une sensation de fraîcheur et de vitalité lors de son utilisation. Il peut être particulièrement agréable pendant les mois d'été ou après une journée chaude, aidant à revitaliser la peau et à stimuler les sens.</p><p>

      Exfoliant doux : Les morceaux de citron broyé présents dans le savon de Marseille peuvent agir comme un exfoliant doux. Ils aident à éliminer les cellules mortes de la peau, favorisant ainsi le renouvellement cellulaire et la douceur de la peau.</p><p>

      Antioxydant : Le citron est riche en antioxydants tels que la vitamine C, qui aident à protéger la peau contre les dommages des radicaux libres. Les propriétés antioxydantes du savon de Marseille au citron broyé contribuent à maintenir une peau saine et jeune.</p>
      `,
      images: [Images.soapLemon,Images.soapLemon2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Citrus limon fruit powder, Palm kernel acid, Sodium chloride, Glycerin, Tetrasodium etidronate, CI 19140, Citral, Geraniol, Limonene.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s11-p",
      name: "Savon de Marseille Lavande Broyée",
      price: "3.99€",
      subTitle: introCube,
      intro: 
      `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi aux extraits de lavande et parfumé à l’huile essentielle de lavandin.`,
      description: `
      <p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Apaisant et relaxant : La lavande est réputée pour ses propriétés apaisantes et relaxantes. L'utilisation du savon de Marseille à la lavande broyée peut aider à calmer la peau et à favoriser une sensation de détente. Son parfum délicat peut également aider à réduire le stress et favoriser un sentiment de bien-être.</p><p>

      Hydratant et adoucissant : Le savon de Marseille à la lavande broyée contient des huiles naturelles qui aident à hydrater et à adoucir la peau. Il peut aider à prévenir la sécheresse cutanée et à maintenir l'équilibre hydrique naturel de la peau, la laissant douce et souple.</p><p>

      Antiseptique : La lavande possède des propriétés antiseptiques qui aident à purifier la peau et à prévenir les infections cutanées. L'utilisation régulière du savon de Marseille à la lavande broyée peut contribuer à maintenir une peau propre et saine.</p><p>

      Anti-inflammatoire : La lavande a des propriétés anti-inflammatoires qui peuvent aider à réduire les rougeurs, les irritations et les inflammations cutanées. Ce savon peut être bénéfique pour les peaux sensibles ou sujettes aux irritations.</p><p>

      Antioxydant : La lavande est riche en antioxydants qui aident à protéger la peau contre les dommages causés par les radicaux libres. L'utilisation du savon de Marseille à la lavande broyée peut contribuer à prévenir les signes du vieillissement prématuré et à maintenir une peau saine et jeune.</p>`,
      images: [Images.soapLavender,Images.soapLavender2,Images.soapLavender3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Lavandula hybrida oil, Lavandula angustifolia flower, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 14700, CI 17200, CI 42090, Geraniol, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s12-p",
      name: "Savon de Marseille Verveine Broyée",
      price: "3.99€",
      subTitle: introCube,
      intro: "Savon de toilette cube 150g à base de savon de Marseille à l’huile d’olive extra pur 80% d'huiles, 2% de parfum, enrichi aux extraits de feuilles de verveine. Savon sans colorant, sans conservateur.",
      description: `
      <p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Rafraîchissant : La verveine a une fragrance citronnée et rafraîchissante, ce qui en fait un excellent choix pour revigorer et rafraîchir la peau. Son parfum agréable laisse une sensation de fraîcheur et de propreté sur la peau.</p><p>
      Revitalisant : La verveine a des propriétés stimulantes qui peuvent aider à revitaliser et à tonifier la peau. Son utilisation régulière peut aider à redonner de l'éclat à la peau terne et fatiguée.</p><p>
      Relaxant : L'arôme de la verveine a également des propriétés relaxantes, ce qui en fait un excellent choix pour apaiser l'esprit et favoriser une sensation de fraîcheur et de bien-être lors de votre routine de soins.</p><p>
      Apaisant : La verveine possède des propriétés apaisantes qui peuvent aider à calmer les irritations cutanées et les rougeurs. Elle est souvent utilisée pour soulager les peaux sensibles ou sujettes aux démangeaisons.
      </p>`,
      images: [Images.soapVerbena,Images.soapVerbena2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium olivate, Aqua, Sodium cocoate, Sodium palm kernelate, Parfum, Lippia citriodora leaf extract, Sodium chloride, Palm kernel acid, Glycerin, Sodium hydroxide, Tetrasodium etidronate, Citral, Citronellol, Eugenol, Geraniol, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s13-p",
      name: "Savon de Marseille Jasmin",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum.`,
      description: `
      <p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Enrichie en beurre de karité biologique, pour une peau toute douce !</p><p>
      Parfum envoûtant : Le jasmin est connu pour son parfum floral et envoûtant. L'utilisation d'un savon de Marseille au jasmin peut laisser un doux parfum sur la peau, offrant une expérience sensorielle agréable lors de la douche ou du bain.</p><p>

      Apaisant et relaxant : Le parfum du jasmin a des propriétés apaisantes qui peuvent aider à détendre l'esprit et à réduire le stress. L'utilisation du savon de Marseille au jasmin peut ainsi favoriser un sentiment de calme et de bien-être pendant la toilette.</p><p>

      Hydratant : Le savon de Marseille contient généralement des huiles végétales, telles que l'huile d'olive, qui est connue pour ses propriétés hydratantes. En utilisant un savon de Marseille au jasmin, vous pouvez aider à maintenir l'hydratation naturelle de votre peau, la laissant douce et souple.</p><p>

      Nettoyant doux : Le savon de Marseille au jasmin peut éliminer efficacement les impuretés et l'excès de sébum, tout en préservant l'équilibre naturel de la peau.</p><p>

      Antioxydant : Le jasmin est riche en antioxydants, qui aident à neutraliser les radicaux libres et à prévenir les dommages causés par les agressions environnementales. L'utilisation régulière d'un savon de Marseille au jasmin peut contribuer à préserver la jeunesse de la peau en la protégeant des signes du vieillissement prématuré.</p>`,
      images: [Images.soapJasmine, Images.soapJasmine2, Images.soapJasmine3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Sodium chloride, Glycerin, Amyl cinnamal, Hexyl cinnamal, Tetrasodium etidronate, Linalool, Hydroxycitronellal, Cinnamyl alcohol, Limonene, CI 19140, CI 17200, CI 14700.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s14-p",
      name: "Savon de Marseille Pétales de Rose Broyés",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, agrémenté de pétales de rose.`,
      description: `
      <p>
      Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Nettoyer les pores de la peau et faciliter le gommage des cellules mortes de l’épiderme tout en laissant un doux parfum sur votre peau.</p><p>
      Hydratant et nourrissant : Les pétales de rose contiennent des huiles naturelles qui aident à hydrater et à nourrir la peau en profondeur. Le savon de Marseille aux pétales de rose broyés peut contribuer à maintenir l'hydratation naturelle de la peau, la laissant douce, souple et éclatante.</p><p>

      Tonifiant et raffermissant : Les propriétés tonifiantes de la rose aident à resserrer les pores de la peau et à améliorer son élasticité. L'utilisation régulière de ce savon peut aider à raffermir la peau, réduire l'apparence des rides et des ridules, et donner un aspect plus jeune.</p><p>

      Régénération cellulaire : Cette exfoliation naturelle repose sur l’élimination des couches supérieures de la peau et des cellules mortes. Elle permet de stimuler la régénération cellulaire.</p><p>

      Purifiant et nettoyant en profondeur : La rose possède des propriétés purifiantes qui aident à éliminer les impuretés, les toxines et l'excès de sébum de la peau. Le savon de Marseille aux pétales de rose broyés nettoie en douceur tout en laissant une sensation de fraîcheur et de propreté.</p><p>

      Parfum agréable et relaxant : L'arôme délicat des pétales de rose offre une expérience sensorielle agréable et relaxante. L'utilisation de ce savon peut aider à apaiser l'esprit, réduire le stress et favoriser un sentiment de bien-être.</p>`,
      images: [Images.soapRose, Images.soapRose2, Images.soapRose3, Images
      .soapRose4],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aquan Parfum, Palm kernel acid, Sodium chloride, Glycerin, Rosa gallica flower extract, Tetrasodium etidronate, CI 14700, CI 17200, Benzyl benzoate, benzyl salicylate, Citronellol, Geraniol, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s15-p",
      name: "Savon de Marseille Framboise",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum.`,
      description: `
      <p>Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      La framboise contient de la vitamine E et de la vitamine C, 100 g de framboises en apporte 25 mg. Cette baie est également source de vitamine E et de B carotènes.</p><p>
      Parfum fruité : La framboise est connue pour son parfum fruité et sucré. L'utilisation d'un savon de Marseille à la framboise peut laisser une fragrance agréable sur la peau, ajoutant une touche fruitée à votre routine de toilette.</p><p>

      Antioxydants : Les framboises sont riches en antioxydants, tels que les vitamines C et E, qui aident à protéger la peau contre les dommages causés par les radicaux libres. Bien que l'effet des antioxydants puisse varier selon la concentration dans le savon, leur présence peut contribuer à préserver la santé et l'éclat de la peau.</p><p>

      Hydratation : Le savon de Marseille est traditionnellement fabriqué à partir d'huiles végétales, telles que l'huile d'olive, qui est réputée pour ses propriétés hydratantes. L'utilisation d'un savon de Marseille à la framboise peut aider à maintenir l'hydratation naturelle de la peau, la laissant douce et souple.</p><p>

      Effet revigorant : L'arôme de framboise peut avoir un effet revigorant et stimulant pour les sens, apportant une sensation de fraîcheur lors de l'utilisation du savon de Marseille à la framboise.</p><p>  
      La framboise est riche en folate, une vitamine connue pour prévenir la chute des cheveux et limiter la repousse des cheveux, et en biotine, qui renforce la fibre capillaire et la brillance. En soin capillaire, elle est souvent utilisée sous forme d'extrait de framboise ou de vinaigre, et est surtout connue pour son odeur agréable et son action anti-calcaire, qui laissera les cheveux doux et brillants.</p>`,
      images: [Images.soapRaspberry, Images.soapRaspberry2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 17200, CI 77499, CI 14700.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s16-p",
      name: "Savon de Marseille Vigne Rouge",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi à la vigne rouge.`,
      description: `
      <p>Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Antioxydant : Le vin rouge est riche en antioxydants, tels que les polyphénols, qui aident à combattre les radicaux libres responsables du vieillissement prématuré de la peau. L'utilisation régulière du savon de Marseille au vin rouge peut aider à préserver la jeunesse de la peau en protégeant contre les dommages causés par les radicaux libres.</p><p>

      Régénération cellulaire : Les propriétés du vin rouge favorisent la régénération cellulaire et aident à maintenir la peau saine et éclatante. Cela peut contribuer à améliorer la texture de la peau et à réduire l'apparence des imperfections.</p><p>

      Hydratation : Le savon de Marseille au vin rouge peut aider à maintenir l'hydratation de la peau. Les composants du vin rouge peuvent aider à retenir l'humidité, ce qui peut contribuer à une peau plus douce et plus souple.</p><p>

      Tonifiant : Le vin rouge a des propriétés tonifiantes qui aident à stimuler la circulation sanguine dans la peau. Cela peut favoriser une apparence plus saine et plus éclatante en donnant un coup de fouet à la peau.</p><p>

      Relaxant : L'arôme subtil du vin rouge peut avoir des effets relaxants sur l'esprit et aider à soulager le stress. Utiliser un savon de Marseille au vin rouge peut donc offrir une expérience de douche ou de bain apaisante.</p><p>
      Grâce à ses vertus hypoallergéniques et bactéricides, il est vivement recommandé par les dermatologues pour les peaux sensibles.</p><p>
      En effet, la vigne rouge permet d'améliorer le retour veineux et soulage les problèmes circulatoires. Si vous avez de petites plaies,  son utilisation est aussi conseillée car elle favorise la cicatrisation.</p>`,
      images: [Images.soapVine, Images.soapVine2, Images.soapVine3,Images.soapVine4],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Vitis vinifera extract, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 19140, CI 14700, CI 17200, CI 77288, CI 77299, Benzyl alcohol, Benzyl benzoate.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s17-p",
      name: "Savon de Marseille Mimosa",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum.`,
      description: `
      <p>Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Effet apaisant : Le mimosa est connu pour son parfum doux et floral, qui évoque une ambiance calme et apaisante. Il est souvent associé à des propriétés apaisantes pour la peau. Son utilisation dans un savon de Marseille peut aider à apaiser les irritations cutanées mineures et à calmer la peau sensible.</p><p>
      Propriétés adoucissantes : Le mimosa est réputé pour ses propriétés adoucissantes et hydratantes pour la peau. Lorsqu'il est utilisé dans un savon de Marseille, il peut contribuer à maintenir l'hydratation naturelle de la peau, laissant une sensation de douceur et de souplesse.</p><p>
      Le mimosa illumine l’hiver et… notre peau !</p><p>
      Le Mimosa tenuiflora appelé “ Arbre à peau ” ou “ Arbre à brûlure” ”, était utilisé autrefois par les Mayas pour son effet calmant et très bénéfique sur la cicatrisation des blessures et des brûlures en particulier. Le mimosa – mimosa tenuiflora bark extract – est également utilisé dans les traitements pour les peaux acnéiques car il resserre efficacement les pores dilatés.</p><p>
      Régénérant, anti-oxydant et préventif anti-âge, le mimosa stimule la régénération de la peau, tout en activant la réparation cellulaire.</p>
      `,
      images: [Images.soapMimosa, Images.soapMimosa2, Images.soapMimosa3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Amyl cinnamal, Geraniol, Tetrasodium etidronate, Linalool, Citronellol, Cinnamyl alcohol, CI 19140, Coumarin.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s18-p",
      name: "Savon de Marseille Thé Vert",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi aux extraits de thé vert.`,
      description: `
      <p>Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Purifiant et détoxifiant : Le thé vert possède des propriétés purifiantes et détoxifiantes qui aident à éliminer les impuretés, les toxines et les résidus de pollution de la peau. Utiliser un savon de Marseille au thé vert peut aider à nettoyer en profondeur la peau, la laissant fraîche et revitalisée.</p><p>
      Antioxydant : Le thé vert est riche en antioxydants naturels tels que les catéchines, qui aident à protéger la peau contre les dommages causés par les radicaux libres. Ces antioxydants aident à prévenir le vieillissement prématuré de la peau et à maintenir son éclat naturel.</p><p>
      Anti-âge : Ce savon est riche aux actifs anti-âges très puissants. L’utilisation du savon au thé vert régulière permet de diminuer les rides, vous pouvez préserver la jeunesse cutanée.</p><p>
      Équilibrant : Le thé vert a la capacité d'équilibrer la production de sébum de la peau, aidant ainsi à réguler les peaux grasses et à réduire l'apparence des pores dilatés. Ce savon contribue de resserrer les peaux grasses. C’est pourquoi il est conseillé de l’utiliser régulièrement si vous avez une peau plutôt grasse. Il laisse la peau nette, fraîche et équilibrée.p><p>
      Revitalisant : Le thé vert est également connu pour ses propriétés stimulantes et revitalisantes. L'utilisation régulière de ce savon peut aider à redonner de l'éclat à la peau fatiguée, en lui donnant une apparence plus saine et radieuse.</p>`,
      images: [Images.soapTea, Images.soapTea2, Images.soapTea3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Camellia sinensis leaf extract, Sodium chloride, Glycerin, Tetrasodium etidronate, CI 77499, CI 77288, Citral, Citronellol, Hydroxycitronellal, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s19-p",
      name: "Savon de Marseille Noyaux d'Abricot",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi aux extraits de noyaux d'abricot.`,
      description: `<p>Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Exfoliation douce : Les noyaux d'abricot présents dans le savon de Marseille agissent comme un agent exfoliant naturel. Ils aident à éliminer les cellules mortes de la peau et à désobstruer les pores, laissant ainsi la peau douce, lisse et éclatante.</p><p>
      Nettoyage en profondeur : Le savon de Marseille aux noyaux d'abricot possède des propriétés nettoyantes efficaces. Il aide à éliminer l'excès de sébum, les impuretés et les toxines de la peau, laissant une sensation de fraîcheur et de propreté.</p><p>
      Hydratation : Bien que le savon de Marseille aux noyaux d'abricot soit un nettoyant efficace, il n'assèche pas excessivement la peau. Il conserve l'hydratation naturelle de la peau, laissant une sensation de confort et d'équilibre.</p><p>
      Stimule la circulation : Lorsque vous utilisez le savon de Marseille aux noyaux d'abricot pour masser délicatement votre peau, cela peut aider à stimuler la circulation sanguine. Une meilleure circulation sanguine peut favoriser l'oxygénation des cellules de la peau et améliorer son éclat naturel.</p><p>
      Riche en nutriments : Les noyaux d'abricot contiennent naturellement des vitamines et des antioxydants bénéfiques pour la peau. L'utilisation régulière du savon de Marseille aux noyaux d'abricot peut aider à nourrir la peau en profondeur, favorisant ainsi sa santé et son éclat.</p><p>
      `,
      images: [Images.soapApricot, Images.soapApricot2,],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Prunus armeniaca fruit extract, Prunus armeniaca seed powder, Palm kernel acid, Parfum, Sodium chloride, Glycerin, Hexyl cinnamal, Linalool, Benzyl alcohol, Geraniol, Tetrasodium etidronate, Limonene, Benzyl salicylate, Amyl cinnamal, CI 15985, CI 17200, CI 14700",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s20-p",
      name: "Savon de Marseille Germes de Blé",
      price: "3.99€",
      subTitle: introCube,
      intro: `A base d’huiles végétales saponifiées et agréablement parfumé. À base de savon de Marseille extra pur 80% d'huiles, 2% de parfum, enrichi aux germes de blé. Savon sans colorant, sans conservateur.`,
      description: `<p>Elaboré à partir de copeaux de savon de Marseille. Les copeaux sont confectionnés sous la responsabilité d’un maître savonnier, à chaud, selon des méthodes anciennes.</p><p>
      Hydratation intense : Les germes de blé contiennent des acides gras essentiels, des vitamines et des minéraux qui aident à nourrir et hydrater en profondeur la peau. Le savon de Marseille aux germes de blé aide à maintenir l'hydratation naturelle de la peau, lui conférant une apparence douce, souple et radieuse.</p><p>
      Régénération cellulaire : Les propriétés régénérantes des germes de blé favorisent la réparation et la régénération des cellules cutanées. Cela peut contribuer à atténuer les signes de vieillissement prématuré, tels que les ridules et les rides, tout en améliorant la texture globale de la peau.</p><p>
      Apaisement et réduction des irritations : Le savon de Marseille aux germes de blé est connu pour ses propriétés apaisantes. Il peut aider à calmer les irritations cutanées, les rougeurs et les démangeaisons, ce qui en fait un choix idéal pour les peaux sensibles ou sujettes aux problèmes dermatologiques tels que l'eczéma ou la dermatite.</p><p>
      Protection contre les radicaux libres : Les germes de blé sont riches en antioxydants tels que la vitamine E, qui contribuent à neutraliser les radicaux libres présents dans l'environnement. Ces radicaux libres peuvent endommager les cellules de la peau et accélérer le processus de vieillissement cutané. Le savon de Marseille aux germes de blé peut aider à protéger la peau des dommages causés par les radicaux libres, tout en favorisant son éclat naturel.</p>
      `,
      images: [Images.soapWheat],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium cocoate, Aqua, Sodium palm kernelate, Parfum, Triticum vulgar bran extract, Palm kernel acid, Sodium chloride, Glycerin, Sodium hydroxide, Tetrasodium etidronate, Benzyl salicylate, Citronellol, Coumarin, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
  ]