export const lavenderSoapIntroEn =
  "PHIOU lavender soap from Provence is a naturally nourishing, moisturizing and soothing product. Its oil-rich composition and captivating fragrance make it a popular choice for taking care of the skin and enjoying a moment of relaxation during the daily cleaning.";

export const marseilleSoapIntro1En =`
PHIOU Marseille soap with olive oil is an emblematic traditional product of the city of Marseille. It is mainly made of olive oil, sea salt, water and soda, which are needed for saponification, the process that turns oils into soap.

The production is carried out according to the traditional method of Marseille soap. It follows a process of slow cooking and prolonged drying, which can take several weeks, in order to obtain a superior quality soap.

It is produced in large cubes or rectangular loaves and then cut into smaller pieces as needed.`

export const marseilleSoapIntro2En = `
Marseille soap with olive oil is renowned for its gentle and nourishing properties for the skin. The olive oil present in its composition is rich in essential fatty acids and vitamins, which helps to moisturize and protect the skin. It is suitable for all skin types, including sensitive skin.

It is used for washing the body, hands and face. It produces a soft and creamy foam in contact with water, facilitating its application and rinsing. It can also be used as a shampoo.
`;