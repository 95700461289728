import { Images } from "../../themes/common";

const perfumeSoapUsage = `This scented soap is ideal for hand and body hygiene, for the whole family! 
External use only.
The product is to be used diluted with water and must be thoroughly rinsed.
Avoid contact with eyes. In case of projection in the eyes, rinse with clear water.`

const marseilleSoapUsage = `This Marseille soap is ideal for hand, body and hair hygiene, for the whole family! External use only.
The product is to be used diluted with water and must be thoroughly rinsed.
Avoid contact with eyes. In case of projection in the eyes, rinse with clear water.
`

const introCube = '150 g handcrafted, ≈ 5.5x5.5x5 cm'
const descMarseilleSoapOlive = `
The traditional method of making authentic Marseille Soap involves cooking the ingredients in an old-fashioned cauldron. This ancient method dates back several centuries and is still used by our manufacturer today.

Thanks to its hypoallergenic and antibacterial properties, this soap is suitable for all skin types, it is also highly recommended for hyper-sensitive skin and can be used for babies.

Olive oil is known for its nourishing qualities for the skin, which is why Marseille soap made with olive oil is an excellent option for those looking to reduce skin dryness while enjoying the benefits of this natural ingredient. It contains no colorants or synthetic additives and is not derived from petroleum or animal fats. It is pure vegetable, biodegradable, free of phosphates and synthetic products, which means it does not pollute and contributes to environmental preservation. It lasts a long time and is particularly economical.

<p> Marseille soap has many virtues and can be used for:</p>
<ul><li> Cleaning - take care of the driest and most sensitive skin. </li>
<li>Caring for wounds - Marseille soap cleans small wounds and abrasions.</li>
<li>As a toothpaste - great for gums and leaves teeth clean and white.</li>
<li>As a stain remover - a stain rubbed before washing with a slightly damp soap will be more effectively removed than with all synthetic detergents (ideal for shirt collars).</li>
<li>Against cramps - place a slice of Marseille soap at the bottom of the bed to relieve cramps and rheumatism.</li>
<li>Against moths - put a Marseille soap in your cupboard to keep moths away.</li>
</ul>
`
const descMarseilleSoapCoco = `The traditional method of making authentic Marseille Soap involves cooking the ingredients in an old-fashioned cauldron. This ancient method dates back several centuries and is still used by our manufacturer today.

Thanks to its hypoallergenic and antibacterial properties, this soap is suitable for all skin types.

This white Marseille soap has hypoallergenic and bactericidal properties which make it particularly recommended by dermatologists for hyper-sensitive skin and can be used for babies.

Marseille soap contains vegetable oils which provide nourishing and moisturizing properties thanks to the presence of coconut oil. It helps to reduce dryness of the skin. Coconut oil is known for its benefits, as it helps retain moisture and keep the skin soft and supple. It may also have antibacterial and soothing properties. Marseille soap is pure vegetable, biodegradable, without phosphates or synthetic products, it does not pollute and contributes to the preservation of the environment. In addition, it is very economical and lasts a very long time.
<p> Marseille soap has many virtues and can be used for:</p>
<ul><li> The toilet - take care of the driest and most sensitive skin. </li>
<li>Caring for wounds - Marseille soap cleans small wounds and abrasions.</li>
<li>As a toothpaste - great for gums and leaves teeth clean and white.</li>
<li>As a stain remover - a stain rubbed before washing with a slightly damp soap will be more effectively removed than with all synthetic detergents (ideal for shirt collars).</li>
<li>Against cramps - place a slice of Marseille soap at the bottom of the bed to relieve cramps and rheumatism.</li>
<li>Against moths - put a Marseille soap in your cupboard to keep moths away.</li>
</ul>
`

const conservation = `<ul><li>Keep it dry: After each use, be sure to dry your Marseille soap thoroughly and store it in a dry place. Excessive moisture can soften the soap and shorten its life.</li>

<li>Use a soap dish: Place your Marseille soap on a soap dish that allows excess water to drain. This helps prevent residue from forming and keeps the soap dry between uses.</li>

<li>Avoid heat sources: Keep your Marseille soap away from direct heat sources, such as radiators</li></ul>`

export const productDataEn = [
  {
    id: "s1-o",
    name: "Olive Oil Marseille Soap",
    price: "2.99€",
    subTitle: "100 g cube of ≈ 5x5x5 cm",
    intro: "Authentic Marseille soap, suitable for body and hand washing, is produced according to ancient methods, under the supervision of a master soap maker. It is cooked hot with 72% vegetal oil and prepared with extra pure olive oil. This soap is free of palm oil, dyes, preservatives and fragrances. It is presented in the form of a raffined cube, measuring approximately 5x5x5 cm and weighing 100 grams on the fresh soap, with a main content of olive oil.",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive100g,Images.soapOlive100g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },  
  {
    id: "s2-o",
    name: "Marseille Soap Vegetable Coconut Oil",
    price: "2.99€",
    subTitle: "100 g cube of ≈ 5x5x5 cm",
    intro: "The real Marseille soap, designed for the most delicate and sensitive skin. Each cube is carefully cooked, glittered, dried and repressed under the supervision of a master soap maker, thus giving a clean and smooth appearance. With 72% oil pure vegetable, rich in saturated fatty acids beneficial for the skin, it does not contain palm oil, coloring, preservatives or artificial fragrances The cube weighs approximately 100 grams on the fresh soap and measures 5x5x5 cm, perfect for everyone's body hygiene ages, from babies to adults.",
    description: descMarseilleSoapCoco,
    images: [Images.soapCoco100g],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: perfumeSoapUsage,
    conservation: conservation
  },  
  {
    id: "s3-o",
    name: "Olive Oil Marseille Soap",
    price: "4.49€",
    subTitle: "300 g cube of ≈ 6.5x6.5x6.5 cm",
    intro: "Authentic Marseille soap, suitable for body and hand washing, is produced according to ancient methods, under the supervision of a master soap maker. It is cooked hot with 72% vegetal oil and prepared with extra pure olive oil. This soap is free of palm oil, dyes, preservatives and fragrances. It is presented in the form of a raffined cube, measuring approximately 6.5x6.5x6.5 cm and weighing 300 grams on the fresh soap, with a main content of olive oil.",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive300g,Images.soapOlive300g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s4-o",
    name: "Olive Oil Marseille Soap",
    price: "5.89€",
    subTitle: "400 g cube of ≈ 6.7.5x7.5x7 cm",
    intro: "Authentic Marseille soap, suitable for body and hand washing, is produced according to ancient methods, under the supervision of a master soap maker. It is cooked hot with 72% vegetal oil and prepared with extra pure olive oil. This soap is free of palm oil, dyes, preservatives and fragrances. It is presented in the form of a raw cube, measuring approximately 6.7.5x7.5x7 cm and weighing 400 grams on the fresh soap, with a main content of olive oil.",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive400g, Images.soapOlive400g2, Images.soapOlive400g3, Images.soapOlive400g4, Images.soapOlive400g5],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s5-o",
    name: "Olive Oil Marseille Soap",
    price: "8.49€",
    subTitle: "600 g cube of ≈ 8.5x8.5x8 cm",
    intro: "Authentic Marseille soap, suitable for body and hand washing, is produced according to ancient methods, under the supervision of a master soap maker. It is cooked hot with 72% vegetal oil and prepared with extra pure olive oil. This soap is free of palm oil, dyes, preservatives and fragrances. It is presented in the form of a raw cube, measuring approximately 8.5x8.5x8 cm and weighing 600 grams on the fresh soap, with a main content of olive oil.",
    description:descMarseilleSoapOlive,
    images: [Images.soapOlive600g, Images.soapOlive600g2, Images.soapOlive600g3, Images.soapOlive600g4],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s6-o",
    name: "Olive Oil Marseille Soap",
    price: "13.59€",
    subTitle: "1000 g cube of ≈ 10x9.8x9.8 cm",
    intro: "Authentic Marseille soap, suitable for body and hand washing, is produced according to ancient methods, under the supervision of a master soap maker. It is cooked hot with 72% vegetal oil and prepared with extra pure olive oil. This soap is free of palm oil, dyes, preservatives and fragrances. It is presented in the form of a raw cube, measuring approximately 10x9.8x9.8 cm and weighing 1000 grams on the fresh soap, with a main content of olive oil.",
    description:descMarseilleSoapOlive,
    images: [Images.soapOlive600g, Images.soapOlive600g2, Images.soapOlive600g3, Images.soapOlive600g4],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium olivate, Sodium cocoate, Aqua, Sodium chloride, Sodium hydroxide.",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s6-p",
    name: "Provence Soap with Lavender",
    price: "4.49€",
    subTitle:"200 g cube vegetable oil of ≈ 6x6x5 cm",
    intro: "Our Provence PHIOU soap with lavender is a traditional product, made in Marseille in the south of France by masters of Savon de Marseille. It is renowned for its quality and natural composition.",
    description: `<p>This Provence lavender soap is composed of 72% oil. This oil is of vegetable origin. It also contains plant extracts， lavender essential oil etc. natural ingredients,.
    </p>
    <p>
    This soap is appreciated for its nourishing and moisturizing properties. The oil present in its composition preserves the hydration of the skin and makes it soft and supple. Lavender, on the other hand, has soothing and relaxing properties, ideal for promoting relaxation and well-being.
    </p>
    <p>
    The Provence PHIOU soap with lavender gives off a delicate and bewitching fragrance. The lavender essential oil used in its manufacture gives it this characteristic scent reminiscent of the lavender fields of Provence.</p>`,
    images: [Images.soapLavender200g,Images.soapLavender200g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "Sodium palmate, Sodium palm kernelate, Aqua, Palm kernel acid, Ricinus communis (castor) seed oil,  Sodium chloride, Glycerin, Lavandula hybrida oil, Tetrasodium edta, Tetrasodium etidronate, Lavandula angustifolia (lavender) oil, CI 73360, CI77007.",
    usageTips: perfumeSoapUsage,
    conservation: conservation
  },
  {
      id: "s7-p",
      name: "Vanilla Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with vanilla.
      `,
      description: `
      <p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Moisturizing properties: Vanilla contains moisturizing compounds that help maintain the skin's natural moisture. When used in a Marseille soap, it can help nourish the skin and prevent dryness, leaving it feeling soft and supple.</p><p>
      Antioxidants: Vanilla is rich in natural antioxidants such as vanillin, which help protect the skin against free radicals and damage caused by environmental factors. These antioxidants can help maintain healthy skin and reduce the signs of premature aging. It softens the skin and brightens the complexion.</p><p>
      Natural scent: Inimitable smell of vanilla, warm and slightly sweet and greedy, with a light foam and a pleasant smell that smells of vacation.
      </p><p>
      Vanilla considered soothing and relaxing. Vanilla soap imparts a soft, warm scent and is a popular scent in many bath products.</p>`,
      images: [Images.soapVanilla,Images.soapVanilla2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium olivate, Aqua, Sodium cocoate, Sodium palm kernelate, Parfum, Palm kernel acid, Sodium chloride, Glycerin, Sodium hydroxide, Tetrasodium etidronate, Coumarin.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s8-p",
      name: "Violet Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with violet.`,
      description: `
      <p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Softening properties: Violet Marseille soap contains natural ingredients that help soften and moisturize the skin. It can help maintain the skin's moisture balance, leaving it soft and supple.</p><p>

      Skin soothing: Violet is renowned for its soothing and soothing properties for the skin. It can help calm minor skin irritations, redness and itching, providing a feeling of comfort.</p><p>

      Lasting Scent: The delicate scent of violet can linger on the skin after rinsing, leaving it feeling fresh and light throughout the day.</p>`,
      images: [Images.soapViolet,Images.soapViolet2, Images.soapViolet3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 42090, CI 17200, Alpha-isomethyl ionone, Hexyl cinnamal.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s9-p",
      name: "Passion Fruit Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume.`,
      description: `
      <p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Exotic scent: Passion fruit is known for its tropical and bewitching scent. Using a passion fruit Marseille soap can bring a pleasant fragrance to your grooming routine, providing a feeling of freshness and escape.</p><p>
      Antioxidants: Passion fruit is rich in antioxidants such as vitamins A and C, which help protect the skin against free radicals and environmental damage. It has a famous asset: passionflower. A mine of vitality for body and hair. Although the effect of antioxidants can vary depending on the concentration in the soap, their presence can help maintain the health and radiance of the skin.</p><p>
      Hydration: Using a passion fruit Marseille soap can help maintain the skin's natural moisture, leaving it soft and supple.</p>
      `,
      images: [Images.soapPassion,Images.soapPassion2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, Hexyl cinnamal, Limonene, CI 14700, CI 17200, CI 77499.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s10-p",
      name: "Crushed Lemon Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with crushed lemon peel.`,
      description: `<p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Ideal for exfoliating and softening the skin.</p><p>
      Purifying and antiseptic: Lemon is known for its purifying and antiseptic properties. Marseille soap with crushed lemon can help eliminate impurities and bacteria present on the skin, thus promoting a feeling of cleanliness and freshness.</p><p>

      Brightening and toning: Lemon is also known for its brightening and toning properties. Marseille soap with crushed lemon can help even skin tone and give skin a natural glow. It can also help reduce the appearance of spots and skin blemishes.</p><p>

      Refreshing: The fresh and lemony aroma of crushed lemon Marseille soap offers a feeling of freshness and vitality during its use. It can be especially pleasant during the summer months or after a hot day, helping to revitalize the skin and stimulate the senses.</p><p>

      Gentle exfoliator: The crushed lemon pieces present in Marseille soap can act as a gentle exfoliator. They help remove dead skin cells, promoting cell renewal and smoothing the skin.</p><p>

      Antioxidant: Lemon is rich in antioxidants such as vitamin C, which help protect the skin against free radical damage. The antioxidant properties of crushed lemon Marseille soap help maintain healthy, youthful skin.</p>
      `,
      images: [Images.soapLemon,Images.soapLemon2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Citrus limon fruit powder, Palm kernel acid, Sodium chloride, Glycerin, Tetrasodium etidronate, CI 19140, Citral, Geraniol, Limonene.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s11-p",
      name: "Crushed Lavender Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: 
      `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with lavender extracts and scented with lavandin essential oil.`,
      description: `
      <p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Soothing and Relaxing: Lavender is renowned for its soothing and relaxing properties. Using crushed lavender Marseille soap can help calm the skin and promote a feeling of relaxation. Its delicate scent can also help reduce stress and promote a sense of well-being.</p><p>

      Moisturizing and Softening: Crushed Lavender Marseille Soap contains natural oils that help moisturize and soften the skin. It can help prevent skin dryness and maintain the skin's natural moisture balance, leaving it soft and supple.</p><p>

      Antiseptic: Lavender has antiseptic properties that help purify the skin and prevent skin infections. Regular use of Crushed Lavender Marseille Soap can help maintain clean, healthy skin.</p><p>

      Anti-inflammatory: Lavender has anti-inflammatory properties that can help reduce skin redness, irritation, and inflammation. This soap may be beneficial for skin that is sensitive or prone to irritation.</p><p>

      Antioxidant: Lavender is rich in antioxidants that help protect the skin against free radical damage. Using Crushed Lavender Marseille Soap can help prevent the signs of premature aging and maintain healthy, youthful looking skin.</p>`,
      images: [Images.soapLavender,Images.soapLavender2,Images.soapLavender3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Lavandula hybrida oil, Lavandula angustifolia flower, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 14700, CI 17200, CI 42090, Geraniol, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s12-p",
      name: "Crushed Verbena Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: "Made from Marseille soap with extra pure olive oil 80% oils, 2% perfume, enriched with verbena leaf extracts. Soap without dye, without preservative",
      description: `
      <p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Refreshing: Verbena has a lemony, refreshing fragrance, making it an excellent choice for invigorating and refreshing the skin. Its pleasant fragrance leaves a feeling of freshness and cleanliness on the skin.</p><p>
      Boost vitality: Verbena can help rejuvenate and condition the skin. Regular use can help restore radiance to dull, tired skin.</p><p>
      Relaxing: The aroma of verbena also has relaxing properties, making it an excellent choice for calming the mind and promoting a feeling of freshness and well-being during your skincare routine.</p><p >
      Soothing: Verbena has soothing properties that can help calm skin irritations and redness. It is often used to relieve sensitive or itchy skin.
      </p>`,
      images: [Images.soapVerbena,Images.soapVerbena2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium olivate, Aqua, Sodium cocoate, Sodium palm kernelate, Parfum, Lippia citriodora leaf extract, Sodium chloride, Palm kernel acid, Glycerin, Sodium hydroxide, Tetrasodium etidronate, Citral, Citronellol, Eugenol, Geraniol, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s13-p",
      name: "Jasmine Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume.`,
      description: `
      <p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Bewitching Scent: Jasmine is known for its floral and bewitching scent. Using a jasmine Marseille soap can leave a sweet scent on the skin, providing a pleasant sensory experience when showering or bathing.</p><p>

      Soothing and relaxing: The scent of jasmine has soothing properties that can help relax the mind and reduce stress. The use of Marseille soap with jasmine can thus promote a feeling of calm and well-being during the wash.</p><p>

      Moisturizing: By using a Marseille soap with jasmine, you can help maintain your skin's natural moisture, leaving it soft and supple.</p><p>

      Gentle cleanser: Jasmine Marseille soap can effectively remove impurities and excess oil, while preserving the skin's natural balance.</p><p>

      Antioxidant: Jasmine is rich in antioxidants, which help neutralize free radicals and prevent damage from environmental aggressors. Regular use of a Marseille soap with jasmine can help preserve the youthfulness of the skin by protecting it from the signs of premature aging.</p>`,
      images: [Images.soapJasmine, Images.soapJasmine2, Images.soapJasmine3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Sodium chloride, Glycerin, Amyl cinnamal, Hexyl cinnamal, Tetrasodium etidronate, Linalool, Hydroxycitronellal, Cinnamyl alcohol, Limonene, CI 19140, CI 17200, CI 14700.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s14-p",
      name: "Crushed Rose Petals Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, embellished with rose petals.`,
      description: `
      <p>
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Cleanse the pores of the skin and facilitate the exfoliation of dead cells from the epidermis while leaving a sweet fragrance on your skin.</p><p>
      Moisturizing and nourishing: Rose petals contain natural oils that help deeply hydrate and nourish the skin. Marseille soap with crushed rose petals can help maintain the skin's natural moisture, leaving it soft, supple and radiant.</p><p>

      Toning and firming: The toning properties of rose help to tighten the pores of the skin and improve its elasticity. Regular use of this soap can help firm skin, reduce the appearance of fine lines and wrinkles, and provide a more youthful appearance.</p><p>

      Cellular regeneration: This natural exfoliation is based on the elimination of the upper layers of the skin and dead cells. It stimulates cell regeneration.</p><p>

      Purifying and deep cleansing: Rose has purifying properties that help remove impurities, toxins and excess oil from the skin. The Marseille soap with crushed rose petals gently cleanses while leaving a feeling of freshness and cleanliness.</p><p>

      Pleasant and Relaxing Scent: The delicate aroma of rose petals provides a pleasant and relaxing sensory experience. Using this soap can help calm the mind, reduce stress, and promote a sense of well-being.</p>`,
      images: [Images.soapRose, Images.soapRose2, Images.soapRose3, Images
      .soapRose4],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aquan Parfum, Palm kernel acid, Sodium chloride, Glycerin, Rosa gallica flower extract, Tetrasodium etidronate, CI 14700, CI 17200, Benzyl benzoate, benzyl salicylate, Citronellol, Geraniol, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s15-p",
      name: "Raspberry Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume.`,
      description: `
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      The raspberry contains vitamin E and vitamin C, 100 g of raspberries provides 25 mg. This berry is also a source of vitamin E and B carotenes.</p><p>
      Fruity scent: Raspberry is known for its fruity and sweet scent. Using a raspberry Marseille soap can leave a pleasant fragrance on the skin, adding a fruity touch to your grooming routine.</p><p>

      Antioxidants: Raspberries are rich in antioxidants, such as vitamins C and E, which help protect the skin against free radical damage. Although the effect of antioxidants can vary depending on the concentration in the soap, their presence can help maintain the health and radiance of the skin.</p><p>

      Hydration: Raspberry Marseille soap helps maintain the skin's natural moisture, leaving it soft and supple.</p><p>

      Invigorating effect: The aroma of raspberry can have an invigorating and stimulating effect on the senses, bringing a feeling of freshness when using raspberry Marseille soap.</p><p>
      Raspberry is rich in folate, a vitamin known to prevent hair loss and limit hair regrowth, and biotin, which strengthens hair fiber and shine. In hair care, it is often used in the form of raspberry extract or vinegar, and is best known for its pleasant smell and its anti-limescale action, which will leave hair soft and shiny.</p>`,
      images: [Images.soapRaspberry, Images.soapRaspberry2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 17200, CI 77499, CI 14700.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s16-p",
      name: "Red Vine Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with red vine.`,
      description: `
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Antioxidant: Red wine is rich in antioxidants, such as polyphenols, which help fight free radicals responsible for premature skin aging. Regular use of Marseille soap with red wine can help preserve the youthfulness of the skin by protecting against free radical damage.</p><p>

      Cell Regeneration: The properties of red wine promote cell regeneration and help maintain healthy, glowing skin. This can help improve skin texture and reduce the appearance of blemishes.</p><p>

      Hydration: Red wine Marseille soap can help maintain skin hydration. The components of red wine can help retain moisture, which can contribute to softer, more supple skin.</p><p>

      Toning: Red wine has toning properties that help stimulate blood circulation in the skin. This can promote a healthier, more radiant appearance by giving the skin a boost.</p><p>

      Relaxing: The subtle aroma of red wine can have relaxing effects on the mind and help relieve stress. Using a red wine Marseille soap can therefore provide a soothing shower or bath experience.</p><p>
      Thanks to its hypoallergenic and bactericidal properties, it is highly recommended by dermatologists for sensitive skin.</p><p>
      Indeed, the red vine improves venous return and relieves circulatory problems. If you have small wounds, its use is also recommended because it promotes healing.</p>`,
      images: [Images.soapVine, Images.soapVine2, Images.soapVine3,Images.soapVine4],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Vitis vinifera extract, Glycerin, Sodium chloride, Tetrasodium etidronate, CI 19140, CI 14700, CI 17200, CI 77288, CI 77299, Benzyl alcohol, Benzyl benzoate.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s17-p",
      name: "Mimosa Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume.`,
      description: `
      Made from shavings of Marseille soap. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Soothing Effect: Mimosa is known for its sweet, floral scent, which evokes a calm and soothing mood. It is often associated with soothing properties for the skin. Using it in a Marseille soap can help soothe minor skin irritations and calm sensitive skin.</p><p>
      Softening properties: Mimosa is renowned for its softening and moisturizing properties for the skin. When used in a Marseille soap, it can help maintain the skin's natural moisture, leaving it feeling soft and supple.</p><p>
      The mimosa lights up the winter and… our skin!</p><p>
      Mimosa tenuiflora, known as “skin tree” or “burn tree”, was once used by the Mayas for its calming and very beneficial effect on the healing of wounds and burns in particular. Mimosa – mimosa tenuiflora bark extract – is also used in treatments for acne-prone skin as it effectively tightens dilated pores.</p><p>
      Regenerating, antioxidant and preventive anti-aging, mimosa stimulates skin regeneration, while activating cell repair.</p>
      `,
      images: [Images.soapMimosa, Images.soapMimosa2, Images.soapMimosa3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Glycerin, Sodium chloride, Amyl cinnamal, Geraniol, Tetrasodium etidronate, Linalool, Citronellol, Cinnamyl alcohol, CI 19140, Coumarin.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s18-p",
      name: "Green Tea Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with green tea extracts.`,
      description: `
      <p>Made from Marseille soap flakes and enriched with green tea extracts. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Purifying and detoxifying: Green tea has purifying and detoxifying properties that help remove impurities, toxins and pollution residue from the skin. Using a green tea Marseille soap can help deeply cleanse the skin, leaving it fresh and revitalised.</p><p>
      Antioxidant: Green tea is rich in natural antioxidants such as catechins, which help protect the skin against free radical damage. These antioxidants help prevent premature aging of the skin and maintain its natural glow.</p><p>
      Anti-aging: This soap is rich in very powerful anti-aging active ingredients. Regular use of green tea soap reduces wrinkles, you can preserve skin youthfulness.</p><p>
      Balancing: Green tea has the ability to balance the sebum production of the skin, helping to regulate oily skin and reduce the appearance of large pores. This soap helps to tighten oily skin. This is why it is advisable to use it regularly if you have rather oily skin. It leaves the skin clear, fresh and balanced.p><p>
      Revitalizing: Green tea is also known for its revitalizing properties. Regular use of this soap can help restore radiance to tired skin, leaving it looking healthier and more radiant.</p>`,
      images: [Images.soapTea, Images.soapTea2, Images.soapTea3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium palm kernelate, Aqua, Parfum, Palm kernel acid, Camellia sinensis leaf extract, Sodium chloride, Glycerin, Tetrasodium etidronate, CI 77499, CI 77288, Citral, Citronellol, Hydroxycitronellal, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s19-p",
      name: "Apricot Kernel Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with apricot kernel extracts.`,
      description:`<p>Made from Marseille soap flakes. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Gentle exfoliation: The apricot kernels present in Marseille soap act as a natural exfoliating agent. They help slough off dead skin cells and unclog pores, leaving skin feeling soft, smooth and glowing.</p><p>
      Deep cleansing: Marseille soap with apricot kernels has effective cleansing properties. It helps eliminate excess sebum, impurities and toxins from the skin, leaving it feeling fresh and clean.</p><p>
      Hydration: Although Marseille soap with apricot kernels is an effective cleanser, it does not overdry the skin. It retains the natural moisture of the skin, leaving a feeling of comfort and balance.</p><p>
      Stimulates circulation: When you use Marseille soap with apricot kernels to gently massage your skin, it can help stimulate blood circulation. Better blood circulation can promote oxygenation of skin cells and enhance its natural glow.</p><p>
      Nutrient-Rich: Apricot kernels naturally contain skin-beneficial vitamins and antioxidants. Regular use of Marseille soap with apricot kernels can help deeply nourish the skin, promoting its health and radiance.</p><p>
      `,
      images: [Images.soapApricot, Images.soapApricot2,],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium, Sodium kernelate, Aqua, Parfum, Prunus armeniaca kernel extract, Glycerin, Sodium chloride, Hexyl cinnamal, Linalool, Benzyl alcohol, Geraniol, Benzyl salicylate, Limonene, Amyl cinnamal,Tetrasodium etidronate, CI 19140, CI 14700, CI 17200, CI 11680, Sodium laureth sulfate, o-phenylphenol.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s20-p",
      name: "Wheat Germ Marseille Soap",
      price: "3.99€",
      subTitle: introCube,
      intro: `Based on saponified vegetable oils and pleasantly scented. Based on extra pure Marseille soap 80% oils, 2% perfume, enriched with wheat germ. Soap without dye, without preservative.`,
      description: `<p>Made from Marseille soap flakes. The shavings are made under the responsibility of a master soap maker, hot, according to ancient methods.</p><p>
      Intense Hydration: Wheat germ contains essential fatty acids, vitamins and minerals that help deeply nourish and hydrate the skin. Marseille soap with wheat germ helps maintain the skin's natural moisture, leaving it looking soft, supple and radiant.</p><p>
      Cell regeneration: The regenerating properties of wheat germ promote the repair and regeneration of skin cells. This can help reduce signs of premature aging, such as fine lines and wrinkles, while improving overall skin texture.</p><p>
      Soothing and reducing irritation: Marseille soap with wheat germ is known for its soothing properties. It can help calm skin irritations, redness and itching, making it an ideal choice for skin that is sensitive or prone to dermatological problems such as eczema or dermatitis.</p><p>
      Protection against free radicals: Wheat germs are rich in antioxidants such as vitamin E, which help neutralize free radicals present in the environment. These free radicals can damage skin cells and accelerate the skin aging process. Marseille soap with wheat germ can help protect the skin from damage caused by free radicals, while promoting its natural glow.</p>
      `,
      images: [Images.soapWheat],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "Sodium palmate, Sodium cocoate, Aqua, Sodium palm kernelate, Parfum, Triticum vulgar bran extract, Palm kernel acid, Sodium chloride, Glycerin, Sodium hydroxide, Tetrasodium etidronate, Benzyl salicylate, Citronellol, Coumarin, Limonene, Linalool.",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
  ];