import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import { Images } from "../themes/common";
import HeaderMenu from "./HeaderMenu";
import { useTranslation } from "react-i18next";

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { t } = useTranslation();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = new Map([
    ["", t("header.welcome")],
    ["brand", t("header.brand")],
    ["products", t("header.products")],
  ]);

  return (
    <AppBar color="primary" position="static">
      <Container maxWidth="xl">
        <Toolbar style={{witdh: '100%'}}>
          <Box>
            <img
              alt="logo"
              src={Images.logo}
              style={{
                height: 100,
                width: 100,
                display: { xs: "none", md: "flex" },
              }}
            />
          </Box>

          {/* Small menu */}
          <Box
            sx={{
              position: "absolute",
              right:100,
              flexGrow: 1,
              display: { xs: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {[...pages].map((page) => {
                const [key, value] = page;
                return (
                  <MenuItem key={key} onClick={handleCloseNavMenu}>
                    <Link key={key} href={`/${key}`} underline="none">
                      <Typography textAlign="center">{value}</Typography>
                    </Link>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>

          {/* Big menu */}
          <Box
            sx={{ ml: "50%", flexGrow: 1, display: { xs: "none", lg: "flex" } }}
          >
            {[...pages].map((page) => {
              const [key, value] = page;
              return (
                <Link key={key} href={`/${key}`}>
                  <Button
                    sx={{ my: 2, color: "white", display: "block" }}
                    textAlign="center"
                  >
                    {value}
                  </Button>
                </Link>
              );
            })}
          </Box>
          <HeaderMenu />
        </Toolbar>
      
      </Container>
    </AppBar>
  );
}
export default Header;
