export const brandIntroFr = [`
Chez Phiou, nous croyons au pouvoir de la nature et à son importance pour notre bien-être. C'est pourquoi nous avons soigneusement conçu nos produits pour exploiter les bienfaits des ingrédients naturels, tout en préservant notre environnement.`,

`Notre gamme comprend des savons de Marseille à base de 72% d'huile d'olive, garantissant une expérience luxueuse et nourrissante pour votre peau. Ces savons sont fabriqués à la main, en suivant la tradition ancestrale de la fabrication du savon de Marseille. À travers notre savon de Marseille, vous pouvez non seulement profiter de produits de haute qualité, mais aussi découvrir l'esprit artisanal traditionnel des artisans français.`,

`De plus, nous proposons une large gamme de savons de Marseille parfumés et de savons de Marseille parfumés à la Provence, inspirés des savons de Marseille traditionnels et enrichis en ingrédients botaniques naturels et en arômes apaisants.`,

`L'une des principales raisons de notre passion pour le savon de Marseille est sa polyvalence. Vous pouvez l'utiliser pour votre corps, votre visage et vos cheveux, simplifiant ainsi votre routine de soins de la peau. Le savon de Marseille est non seulement doux, mais aussi un antiseptique efficace, contribuant à nettoyer et purifier naturellement votre peau.`,

`Un autre avantage du savon de Marseille est sa longévité. Il ne se périme jamais, en faisant un choix durable et économique. En choisissant le savon de Marseille, vous réduisez les déchets et contribuez à un mode de vie plus respectueux de l'environnement.`,

`Nous nous engageons à promouvoir les bienfaits du savon de Marseille et à soutenir les artisans qui perpétuent cette tradition. Les fabricants de savon de Marseille sont confrontés à des défis sur le marché actuel, c'est pourquoi nous espérons sensibiliser et valoriser le savon de Marseille et sa qualité exceptionnelle à travers nos produits.`,

`Partez à la découverte des merveilles du savon de Marseille avec Phiou et vivez une expérience enrichissante et bienfaisante. Laissez la nature et la tradition inspirer votre parcours de soins de soi.`];