import ValueCards from "../components/Brand/ValueCards";
import Title from "../components/Brand/Title";
import Image from "../components/Brand/Image";

const BrandPage = () => {
  return (
    <>
      <Image />

      <Title />

      <ValueCards />

      {/* <Paragraphs /> */}
    </>
  );
};

export default BrandPage;
