import { Images } from "../../themes/common";

const perfumeSoapUsage = `这款马赛皂非常适合全家人使用，用于手部和身体的清洁！仅供外部使用。
产品需稀释后与水混合使用，并需要彻底冲洗。
避免接触眼睛。如果不慎溅入眼睛，请用清水冲洗。`

const marseilleSoapUsage = `这款马赛皂非常适合全家人使用，用于手部、身体和头发的洗护！仅供外部使用。
产品需稀释后与水混合使用，并需要彻底冲洗。
避免接触眼睛。如果不慎溅入眼睛，请用清水冲洗。
`

const introCube = '150克 手工制作 ≈ 5.5x5.5x5厘米'
const descMarseilleSoapOlive = `
制作正宗马赛皂的传统方法是在老式大锅中烹饪原料，这种古老的方法可以追溯到几个世纪前，至今我们的制造商仍在使用。

由于其低过敏性和抗菌特性，这款马赛皂适合所有皮肤类型，也强烈推荐用于超敏感皮肤，并可用于婴儿。

橄榄油以其对皮肤的滋养功效而闻名，这就是为什么用橄榄油制成的马赛皂对于那些希望减少皮肤干燥同时享受这种天然成分的好处的人来说是一个绝佳的选择。它不含着色剂或合成添加剂，也不是从动物脂肪中提取的，它是纯植物性的，可生物降解，不含磷酸盐和合成产品，这意味着它不会污染，有助于环境保护。它的使用寿命很长，而且特别经济。

<p>马赛皂有很多优点，可用于：</p>
<ul><li>洗护 - 照顾最干燥和最敏感的皮肤。</li>
<li>护理 - 马赛皂可以清洁小伤口和擦伤。</li>
<li>牙膏 - 非常适合牙龈并保持牙齿清洁洁白。</li>
<li>去污 - 清洗前用微湿的马赛皂擦拭污渍比使用所有合成清洁剂更有效（最适合衬衫领子）。</li>
<li>防蛀 - 在橱柜里放一块马赛皂，可以驱除蛀虫。</li>
</ul>
`
const descMarseilleSoapCoco = `制作正宗马赛皂的传统方法是在老式大锅中烹饪原料，这种古老的方法可以追溯到几个世纪前，至今我们的制造商仍在使用。

由于其低过敏性和抗菌特性，马赛皂适合所有皮肤类型，也特别推荐用于超敏感皮肤，并且可以用于婴儿。

马赛皂中的椰子油，可提供滋养和保湿特性。它有助于减少皮肤干燥。椰子油有助于保持水分并保持皮肤柔软。它还具有抗菌和舒缓作用。马赛皂是纯植物性的，可生物降解，不含磷酸盐或合成产品，不污染，有助于保护环境。此外，它非常经济且持续时间很长。
<p>马赛皂有很多优点，可用于：</p>
<ul><li>洗护 - 照顾最干燥和最敏感的皮肤。</li>
<li>护理 - 马赛皂可以清洁小伤口和擦伤。</li>
<li>牙膏 - 非常适合牙龈并保持牙齿清洁洁白。</li>
<li>去污 - 清洗前用微湿的马赛皂擦拭污渍比使用所有合成清洁剂更有效（最适合衬衫领子）。</li>
<li>防蛀 - 在橱柜里放一块马赛皂，可以驱除蛀虫。</li>
</ul>
`
const conservation = `<ul><li>保持干燥：每次使用后，请彻底干燥马赛皂并将其存放在干燥的地方。 过多的水分会使马赛皂软化并缩短其使用寿命。</li>
<li>使用皂架：将马赛皂放在皂架上，以便排出多余的水。这有助于防止残留物的形成，并在使用期间保持皂体干燥。</li>
<li>避免热源：让您的马赛皂远离直接热源，例如散热器</li></ul>`

export const productDataCn = [
  {
    id: "s1-o",
    name: "橄榄油马赛皂",
    price: "38¥",
    subTitle: "100克 ≈ 5x5x5厘米 立方体",
    intro: "传统正宗马赛皂，适合洗手沐浴洗发，是在马赛皂大师的监督下按照古老的方法生产的。它是用72%的植物油热烹制成，使用的是超纯橄榄油。马赛皂中不含棕榈油、染料、防腐剂和香料。它以精致立方体的形式呈现，尺寸约为5x5x5厘米，新鲜出炉重量约100克，主含成分为橄榄油。",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive100g,Images.soapOlive100g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "橄榄酸钠、椰油酸钠、水、氯化钠、氢氧化钠。",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },  
  {
    id: "s2-o",
    name: "植物油马赛皂",
    price: "38¥",
    subTitle: "100克 椰子油 ≈ 5x5x5厘米 立方体",
    intro: "传统正宗马赛皂，为最娇嫩、敏感的肌肤而设计。每块皂块都在制皂大师的监督下经过精心烹煮、切割、干燥、和压制，从而呈现干净光滑的外观。含72%油的纯植物成分，富含对皮肤有益的饱和脂肪酸，不含棕榈油、色素、防腐剂或人工香料。新鲜出炉重量约100克，尺寸为5x5x5厘米，从婴儿到成人，都非常适合各个年龄段的洗护需求。",
    description: descMarseilleSoapCoco,
    images: [Images.soapCoco100g],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "椰油酸钠、水、氯化钠、氢氧化钠。",
    usageTips: perfumeSoapUsage,
    conservation: conservation
  },  
  {
    id: "s3-o",
    name: "300克橄榄油马赛皂",
    price: "58¥",
    subTitle: "300克 ≈ 6.5x6.5x6.5厘米 立方体",
    intro: "传统正宗马赛皂，适合洗手沐浴洗发，是在马赛皂大师的监督下按照古老的方法生产的。它是用72%的植物油热烹制成，使用的是超纯橄榄油。马赛皂中不含棕榈油、染料、防腐剂和香料。它以精致立方体的形式呈现，尺寸约为6.5x6.5x6.5厘米，新鲜出炉重量约300克，主含成分为橄榄油。",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive300g,Images.soapOlive300g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "橄榄酸钠、椰油酸钠、水、氯化钠、氢氧化钠。",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s4-o",
    name: "400克橄榄油马赛皂",
    price: "68¥",
    subTitle: "400克 ≈ 7.5x7.5x7厘米 立方体",
    intro: "传统正宗马赛皂，适合洗手沐浴洗发，是在马赛皂大师的监督下按照古老的方法生产的。它是用72%的植物油热烹制成，使用的是超纯橄榄油。马赛皂中不含棕榈油、染料、防腐剂和香料。它以原始立方体的形式呈现，尺寸约为7.5x7.5x7厘米，新鲜出炉重量约400克，主含成分为橄榄油。",
    description: descMarseilleSoapOlive,
    images: [Images.soapOlive400g, Images.soapOlive400g2, Images.soapOlive400g3, Images.soapOlive400g4, Images.soapOlive400g5],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "橄榄酸钠、椰油酸钠、水、氯化钠、氢氧化钠。",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s5-o",
    name: "600克橄榄油马赛皂",
    price: "88¥",
    subTitle:"600克 ≈ 8.5x8.5x8厘米 立方体",
    intro: "传统正宗马赛皂，适合洗手沐浴洗发，是在马赛皂大师的监督下按照古老的方法生产的。它是用72%的植物油热烹制成，使用的是超纯橄榄油。马赛皂中不含棕榈油、染料、防腐剂和香料。它以原始立方体的形式呈现，尺寸约为8.5x8.5x8厘米，新鲜出炉重量约600克，主含成分为橄榄油。",
    description:descMarseilleSoapOlive,
    images: [Images.soapOlive600g, Images.soapOlive600g2, Images.soapOlive600g3, Images.soapOlive600g4],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "橄榄酸钠、椰油酸钠、水、氯化钠、氢氧化钠。",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s6-o",
    name: "1000克橄榄油马赛皂",
    price: "128¥",
    subTitle:"1000克 ≈ 10x9.8x9.8厘米 立方体",
    intro: "传统正宗马赛皂，适合洗手沐浴洗发，是在马赛皂大师的监督下按照古老的方法生产的。它是用72%的植物油热烹制成，使用的是超纯橄榄油。马赛皂中不含棕榈油、染料、防腐剂和香料。它以原始立方体的形式呈现，尺寸约为10x9.8x9.8厘米，新鲜出炉重量约1000克，主含成分为橄榄油。",
    description:descMarseilleSoapOlive,
    images: [Images.soapOlive600g, Images.soapOlive600g2, Images.soapOlive600g3, Images.soapOlive600g4],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "橄榄酸钠、椰油酸钠、水、氯化钠、氢氧化钠。",
    usageTips: marseilleSoapUsage,
    conservation: conservation
  },
  {
    id: "s6-p",
    name: "普罗旺斯薰衣草皂",
    price: "68¥",
    subTitle: "200克 植物油 ≈ 6x6x5厘米 立方体",
    intro: `<p>这款普罗旺斯薰衣草香皂由72%的植物油组成。另外还含有植物提取物，薰衣草精油等天然成分。
    </p>
    <p>
    普罗旺斯薰衣草皂因其滋养和保湿特性而受到赞赏。其成分中的植物油可以保持皮肤的水合作用，使皮肤柔软。另外，薰衣草具有舒缓和放松的特性，它在给大家放松精神的同时给大家带来愉悦的心情。
    </p>
    <p>
    PHIOU普罗旺斯薰衣草皂散发出细腻迷人的香味。其制造过程中使用的薰衣草精油赋予其特有的香味，很容易让人联想起普罗旺斯浪漫的薰衣草地。</p>`,
    images: [Images.soapLavender200g,Images.soapLavender200g2],
    icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
    ingredient:
      "棕榈酸钠、棕榈仁酸钠、水、棕榈仁酸、蓖麻籽油、氯化钠、甘油、薰衣草油、乙二胺四乙酸四钠、依替磷酸四钠、薰衣草油、CI 73360、CI77007。",
    usageTips: perfumeSoapUsage,
    conservation: conservation
  },
  {
      id: "s7-p",
      name: "香草马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含香草植物。  
      `,
      description: `
      <p>
       以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
       保湿特性：香草含有保湿化合物，有助于保持皮肤的天然水分。添加在马赛皂中可以帮助滋养皮肤并防止干燥，使皮肤感觉柔软。</p><p>

       抗氧化：香草富含香草醛等天然抗氧化剂，有助于保护皮肤免受自由基和环境因素造成的损害。这些抗氧化剂可以帮助保持皮肤健康并减少过早衰老的迹象。它可以软化皮肤并提亮肤色。</p><p>
       天然香味：独特的香草味，温暖而微甜腻，带有淡淡的泡沫，有种度假的宜人气味。
       </p><p>
       香草被认为具有舒缓和放松的作用。香草皂散发出柔和温暖的香味，是许多沐浴产品中流行的香味。</p>`,
      images: [Images.soapVanilla,Images.soapVanilla2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、橄榄酸钠、水、椰油酸钠、棕榈仁酸钠、香料、棕榈仁酸、氯化钠、甘油、氢氧化钠、依替膦酸四钠、香豆素。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s8-p",
      name: "紫罗兰马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含紫罗兰植物。`,
      description: `
      <p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      软化肌肤：紫罗兰马赛皂含有天然成分，有助于软化和滋润皮肤。它可以帮助维持皮肤的水分平衡，使皮肤柔软。</p><p>
      舒缓肌肤：紫罗兰以其舒缓肌肤的功效而闻名。它可以帮助缓解轻微的皮肤刺激、发红和瘙痒，带来舒适感。</p><p>
      香味持久：冲洗后，淡淡的紫罗兰香味会残留在肌肤上，让肌肤一整天都感觉清新、轻盈。</p>`,
      images: [Images.soapViolet,Images.soapViolet2, Images.soapViolet3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、香水、棕榈仁酸、甘油、氯化钠、依替膦酸四钠、CI 42090、CI 17200、α-异甲基紫罗兰酮、己基肉桂醛。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s9-p",
      name: "百香果马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水。`,
      description: `
      <p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      异国情调：百香果以其热带迷人的香气而闻名。百香果马赛香皂可以为您的日常护理带来宜人的香味，带来清新和放松的感觉。</p><p>
      抗氧化：百香果富含维生素A和C等抗氧化剂，有助于保护皮肤免受自由基和环境损害。它有一个著名的活性成分：西番莲，是身体和头发的活力矿泉。抗氧化剂的存在有助于保持皮肤的健康和光泽。</p><p>
      保湿：使用百香果马赛皂可以帮助保持皮肤的天然水分，使其柔软。</p>
      `,
      images: [Images.soapPassion,Images.soapPassion2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、香水、棕榈仁酸、甘油、氯化钠、依替膦酸四钠、己基肉桂醛、柠檬烯、CI 14700、CI 17200、CI 77499。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s10-p",
      name: "柠檬碎马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含柠檬碎植物。`,
      description: `<p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      非常适合去角质和软化皮肤。</p><p>
      净化和抗菌：柠檬以其净化和抗菌特性而闻名。含有柠檬碎的马赛皂可以帮助消除皮肤上的杂质和细菌，从而促进清洁和清新的感觉。</p><p>

      提亮和调色：柠檬可以提亮肤色。含有柠檬碎的马赛皂可以帮助均匀肤色，让肌肤焕发自然光泽。它还可以帮助减少斑点和皮肤瑕疵的出现。</p><p>

      清爽：柠檬碎马赛皂的清新柠檬香气，在使用过程中给人一种清新、活力的感觉。在夏季或炎热的一天之后，它会特别令人愉快，有助于使皮肤焕发活力并刺激感官。</p><p>

      温和去角质：马赛皂中的柠檬碎片可以起到温和的去角质剂的作用。它们有助于去除死皮细胞，促进细胞更新并使皮肤光滑。</p><p>

      抗氧化：柠檬富含维生素C等抗氧化剂，有助于保护皮肤免受自由基损伤。柠檬碎马赛皂的抗氧化特性有助于保持健康、年轻的皮肤。</p>
      `,
      images: [Images.soapLemon,Images.soapLemon2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、香水、柑橘柠檬果粉、棕榈仁酸、氯化钠、甘油、依替膦酸四钠、CI 19140、柠檬醛、香叶醇、柠檬烯。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s11-p",
      name: "薰衣草碎马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含薰衣草提取物，并带有薰衣草精油香味。`,
      description: `
      <p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      舒缓和放松：薰衣草以其舒缓和放松的特性而闻名。使用薰衣草碎马赛皂可以帮助镇静皮肤并促进放松的感觉。其淡淡的香味还可以帮助减轻压力并促进幸福感。</p><p>

      保湿和软化：薰衣草碎马赛皂含有天然油脂，有助于滋润和软化皮肤。它可以帮助防止皮肤干燥并保持皮肤的自然水分平衡，使皮肤柔软。</p><p>

      抗菌：薰衣草具有抗菌特性，有助于净化皮肤并预防皮肤感染。经常使用薰衣草碎马赛皂有助于保持皮肤清洁、健康。</p><p>

      抗炎：薰衣草具有抗炎特性，有助于减少皮肤发红、刺激和炎症。这种马赛皂对敏感或容易受到刺激的皮肤有益。</p><p>

      抗氧化：薰衣草富含抗氧化剂，有助于保护皮肤免受自由基损伤。使用薰衣草碎马赛皂有助于预防过早衰老的迹象，保持健康、年轻的皮肤。</p>`,
      images: [Images.soapLavender,Images.soapLavender2,Images.soapLavender3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、薰衣草油、薰衣草花、棕榈仁酸、甘油、氯化钠、依替膦酸四钠、CI 14700、CI 17200、CI 42090、香叶醇、柠檬烯、芳樟醇。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s12-p",
      name: "马鞭草碎马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: 
      `以超纯马赛皂为基础，含80%植物油、2%香水，富含马鞭草叶提取物。不含染料、不含防腐剂。`,
      description: `
      <p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      清爽：马鞭草具有柠檬般的清爽香气，是让肌肤焕发活力、清爽的绝佳选择。其宜人的香味给皮肤带来清新的感觉。</p><p>
      提升活力：马鞭草可以帮助恢复活力和调理皮肤。经常使用可以帮助暗沉、疲惫的肌肤恢复光泽。</p><p>
      放松：马鞭草的香气还具有放松的功效，使其成为在日常护肤过程中镇静心灵、促进清新和幸福感的绝佳选择。</p><p >
      舒缓：马鞭草具有舒缓功效，有助于缓解皮肤刺激和发红。它通常用于缓解敏感或瘙痒的皮肤。
      </p>`,
      images: [Images.soapVerbena,Images.soapVerbena2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、橄榄酸钠、水、椰油酸钠、棕榈仁酸钠、香水、柠檬叶提取物、氯化钠、棕榈仁酸、甘油、氢氧化钠、依替膦酸四钠、柠檬醛、香茅醇、丁子香酚、香叶醇、柠檬烯、芳樟醇。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s13-p",
      name: "茉莉花马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水。`,
      description: `
      <p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>

      迷人的香味：茉莉花以其花香和迷人的香味而闻名。使用茉莉马赛皂可以在皮肤上留下甜美的香味，在淋浴或沐浴时提供愉悦的感官体验。</p><p>

      舒缓和放松：茉莉花的香味具有舒缓的特性，可以帮助放松心情，减轻压力。</p><p>

      保湿：含有茉莉花的马赛皂，您可以帮助保持皮肤的天然水分，使其柔软。</p><p>

      温和洁面：茉莉马赛皂能有效去除杂质和多余油脂，同时保持肌肤的自然平衡。</p><p>

      抗氧化：茉莉花富含抗氧化剂，有助于中和自由基并防止环境侵害者造成的损害。经常使用茉莉花马赛可以防止皮肤出现过早衰老的迹象，从而保持皮肤的年轻状态。</p>`,
      images: [Images.soapJasmine, Images.soapJasmine2, Images.soapJasmine3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、香水、棕榈仁酸、氯化钠、甘油、戊基肉桂醛、己基肉桂醛、依替膦酸四钠、芳樟醇、羟基香茅醛、肉桂醇、柠檬烯、CI 19140、CI 17200、CI 14700。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s14-p",
      name: "玫瑰花瓣马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro:`基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，并伴有玫瑰花瓣。`, 
      description: `
      <p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      清洁皮肤毛孔，促进表皮死细胞脱落，同时在皮肤上留下甜美的香味。</p><p>
      保湿滋养：玫瑰花瓣含有天然油脂，有助于深层滋润和滋养肌肤。含有玫瑰花瓣的马赛皂可以帮助保持皮肤的天然水分，使皮肤柔软、水嫩、容光焕发。</p><p>

      调理和紧致：玫瑰的调理特性有助于收紧皮肤毛孔并提高其弹性。经常使用这种马赛皂可以帮助紧致肌肤，减少细纹和皱纹，让您看起来更年轻。</p><p>

      细胞再生：这种自然去角质是基于消除皮肤上层和死细胞。它刺激细胞再生。</p><p>

      净化和深层清洁：玫瑰具有净化特性，有助于去除皮肤上的杂质、毒素和多余油脂。含有玫瑰花瓣的马赛皂可温和清洁，同时留下清新干净的感觉。</p><p>

      令人愉悦和放松的香味：玫瑰花瓣的精致香气提供令人愉悦和放松的感官体验，可以帮助平静心情、减轻压力并促进幸福感。</p>`,
      images: [Images.soapRose, Images.soapRose2, Images.soapRose3, Images
      .soapRose4],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、Aquan Parfum、棕榈仁酸、氯化钠、甘油、玫瑰花提取物、依替膦酸四钠、CI 14700、CI 17200、苯甲酸苄酯、水杨酸苄酯、香茅醇、香叶醇、芳樟醇。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s15-p",
      name: "覆盆子马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水。`, 
      description: `<p>
      以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      覆盆子含有维生素E和维生素C，100克覆盆子提供25毫克。这种浆果也是维生素E和B胡萝卜素的来源。</p><p>
      果香：覆盆子以其果香和甜味而闻名。覆盆子马赛皂可以在皮肤上留下宜人的香味，为您的日常美容增添果香。</p><p>

      抗氧化：覆盆子富含抗氧化剂，例如维生素C和E，有助于保护皮肤免受自由基损伤。它们的存在有助于保持皮肤的健康和光泽。</p><p>

      保湿：覆盆子马赛皂可以帮助保持皮肤的天然水分，使其柔软。</p><p>

      提神作用：覆盆子的香气可以对感官产生提神、刺激的作用，使用覆盆子马赛皂时会带来清新的感觉。</p><p>
      覆盆子富含叶酸和生物素，叶酸是一种已知可以防止脱发和限制头发再生的维生素，生物素可以增强头发纤维和光泽。在护发方面，它经常以覆盆子提取物或醋的形式使用，以其宜人的气味和抗水垢作用而闻名，使头发柔软有光泽。</p>`,
      images: [Images.soapRaspberry, Images.soapRaspberry2],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水族、香料、棕榈仁酸、甘油、氯化钠、依替膦酸四钠、CI 17200、CI 77499、CI 14700。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s16-p",
      name: "红葡萄藤马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含红葡萄藤。`, 
      description: `
      <p>以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      抗氧化：红葡萄藤富含抗氧化剂，例如多酚，有助于对抗导致皮肤过早衰老的自由基。 经常使用可以防止自由基损伤，帮助保持皮肤年轻。</p><p>

      细胞再生：红葡萄藤特性促进细胞再生，有助于保持健康、容光焕发的皮肤。这有助于改善皮肤纹理并减少瑕疵的出现。</p><p>

      保湿：红葡萄藤马赛皂可以帮助保持皮肤保湿。它的成分有助于保持水分，从而使皮肤更加柔软。</p><p>

      调理：红葡萄藤具有调理功效，有助于刺激皮肤的血液循环。促进皮肤健康、容光焕发。</p><p>

      放松：红葡萄藤淡淡的香气可以起到放松心灵的作用，有助于缓解压力。因此，使用红葡萄藤马赛皂可以提供舒缓的淋浴或沐浴体验。</p><p>
      事实上，红葡萄藤可以改善静脉回流并缓解循环问题。如果您有小伤口，也建议使用它，因为它可以促进愈合。</p>`,
      images: [Images.soapVine, Images.soapVine2, Images.soapVine3,Images.soapVine4],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、香水、棕榈仁酸、葡萄提取物、甘油、氯化钠、依替膦酸四钠、CI 19140、CI 14700、CI 17200、CI 77288、CI 77299、苯甲醇、苯甲酸苄酯。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s17-p",
      name: "含羞草马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水。`, 
      description: `
      <p>以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      舒缓效果：含羞草以其甜美的花香而闻名，能唤起平静和舒缓的心情。它通常与舒缓皮肤的特性有关。在马赛皂中使用它可以帮助舒缓轻微的皮肤刺激并镇静敏感皮肤。</p><p>
      软化特性：含羞草以其对皮肤的软化和保湿特性而闻名。当用于马赛皂时，它可以帮助保持皮肤的天然水分，使其感觉柔软。</p><p>
      含羞草照亮了冬天……我们的皮肤！</p><p>
      含羞草，被称为“皮肤树”或“烧伤树”，曾经被玛雅人使用，因为它具有镇静作用，尤其对伤口和烧伤的愈合非常有益。含羞草（含羞草树皮提取物）也可用于治疗痤疮皮肤，因为它能有效收紧扩张的毛孔。</p><p>
      含羞草具有再生、抗氧化和预防性抗衰老作用，可刺激皮肤再生，同时激活细胞修复。</p>
      `,
      images: [Images.soapMimosa, Images.soapMimosa2, Images.soapMimosa3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、香料、棕榈仁酸、甘油、氯化钠、戊基肉桂醛、香叶醇、依替膦酸四钠、芳樟醇、香茅醇、肉桂醇、CI 19140、香豆素。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s18-p",
      name: "绿茶马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含绿茶提取物。`, 
      description: `
      <p>以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      净化排毒：绿茶具有净化排毒功效，有助于去除皮肤上的杂质、毒素和污染残留物。 使用绿茶马赛皂可以帮助深层清洁皮肤，使皮肤清新、焕发活力。</p><p>
      抗氧化：绿茶富含儿茶素等天然抗氧化剂，有助于保护皮肤免受自由基损伤。这些抗氧化剂有助于防止皮肤过早老化并保持其自然光泽。</p><p>
      抗衰老：它富含非常强大的抗衰老活性成分。经常使用绿茶马赛皂可以减少皱纹，可以保持皮肤年轻。</p><p>
      平衡：绿茶具有平衡皮肤皮脂分泌的能力，有助于调节油性皮肤并减少粗大毛孔的出现。这种马赛皂有助于收紧油性皮肤。这就是为什么如果您的皮肤相当油性，建议定期使用它的原因。它让肌肤清澈、清新、平衡。</p><p>>
      恢复活力：绿茶还以其激活和恢复活力的特性而闻名。经常使用可以帮助疲惫的皮肤恢复光泽，使其看起来更健康、更有光泽。</p>`,
      images: [Images.soapTea, Images.soapTea2, Images.soapTea3],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、香水、棕榈仁酸、茶树叶提取物、氯化钠、甘油、依替膦酸四钠、CI 77499、CI 77288、柠檬醛、香茅醇、羟基香茅醛、柠檬烯、芳樟醇。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s19-p",
      name: "杏仁马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含杏仁提取物。`, 
      description: `<p>以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      温和去角质：马赛皂中的杏仁是一种天然去角质剂。它们有助于去除死皮细胞并疏通毛孔，使皮肤感觉柔软、光滑和容光焕发。</p><p>
      深层清洁：马赛杏仁皂具有有效的清洁功效。它有助于消除皮肤中多余的皮脂、杂质和毒素，让皮肤感觉清新干净。</p><p>
      保湿：虽然杏仁马赛皂是一种有效的清洁剂，但它不会使皮肤过度干燥。它保留了皮肤的自然水分，留下舒适和平衡的感觉。</p><p>
      促进血液循环：使用杏仁马赛皂轻轻按摩皮肤，有助于促进血液循环。更好的血液循环可以促进皮肤细胞的氧合并增强其自然光泽。</p><p>
      营养丰富：杏仁天然含有对皮肤有益的维生素和抗氧化剂。经常使用杏仁马赛皂可以帮助深层滋养肌肤，促进肌肤健康和光泽。</p>
      `,
      images: [Images.soapApricot, Images.soapApricot2,],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、棕榈仁酸钠、水、杏果提取物、杏籽粉、棕榈仁酸、香料、氯化钠、甘油、己基肉桂醛、芳樟醇、苯甲醇、香叶醇、依替膦酸四钠、柠檬烯、水杨酸苄酯、戊基肉桂醛，CI 15985，CI 17200，CI 14700",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
    {
      id: "s20-p",
      name: "小麦芽马赛皂",
      price: "65¥",
      subTitle: introCube,
      intro: `基于皂化植物油，具有宜人的香味。以超纯马赛皂为基础，含80%植物油、2%香水，富含小麦胚芽。不含染料、不含防腐剂。`, 
      description: `<p>以马赛皂屑为基础，皂屑是由马赛皂大师按照古老的方法热制而成的。</p><p>
      强效保湿：小麦胚芽含有必需脂肪酸、维生素和矿物质，有助于深层滋养和滋润肌肤。含有小麦胚芽的马赛皂有助于保持皮肤的天然水分，使其看起来柔软、水嫩、容光焕发。</p><p>
      细胞再生：小麦胚芽的再生特性促进皮肤细胞的修复和再生。有助于减少细纹和皱纹等过早衰老的迹象，同时改善整体皮肤纹理。</p><p>
      舒缓和减少刺激：含有小麦胚芽的马赛皂以其舒缓特性而闻名。它可以帮助缓解皮肤刺激、发红和瘙痒，使其成为敏感或容易出现湿疹或皮炎等皮肤病问题的皮肤的理想选择。</p><p>
      抵御自由基：小麦胚芽富含维生素E等抗氧化剂，有助于中和环境中存在的自由基。这些自由基会损害皮肤细胞并加速皮肤老化过程。含有小麦胚芽的马赛皂可以帮助保护皮肤免受自由基造成的伤害，同时促进其自然光泽。</p>
      `,
      images: [Images.soapWheat],
      icons: [Images.iconCube, Images.iconMIF, Images.iconVegan],
      ingredient:
        "棕榈酸钠、椰油酸钠、水、棕榈仁酸钠、香水、小麦麸皮提取物、棕榈仁酸、氯化钠、甘油、氢氧化钠、依替膦酸四钠、水杨酸苄酯、香茅醇、香豆素、柠檬烯、芳樟醇。",
      usageTips: perfumeSoapUsage,
      conservation: conservation
    },
  ]