import { Grid, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Images } from "../../themes/common";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";

const PhysicShop = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      wrap="nowrap"
      spacing={2}
      sx={{
        mb: 4,
        justifyContent: "center",
      }}
    >
      <Grid item  xs={12} lg={8}>
        <Typography
          gutterBottom
          variant="h4"
          textAlign={"center"}
          style={{ marginBottom: 20 }}
        >
          {t("homePage.ourShop")}
        </Typography>
        <Carousel>
          <div>
            <img alt="Phiou-Shop1" src={Images.phiouShop2} />
          </div>
          <div>
            <img src={Images.phiouShop1} />
          </div>
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default PhysicShop;
