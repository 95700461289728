import logo from "../assets/logo.png";
import eiffelTower from "../assets/eiffel-tower.jpg";
import olive100 from "../assets/olive100.jpg";
import lavender from "../assets/lavender.jpg";
import phiouShop1 from "../assets/phiou-shop1.jpg";
import phiouShop2 from "../assets/phiou-shop2.jpg";
import taobao from "../assets/taobao.png";
import tmall from "../assets/tmall.png";
import jd from "../assets/jd.jpeg";
import redbook from "../assets/redbook.png";
import lavenderSoap from "../assets/lavender-soap.png";
import parcel from "../assets/parcel.png";
import france from "../assets/france.png";
import onlineOffline from "../assets/online_offline.png";
import vegan from "../assets/vegan.png";
import soap from "../assets/soap.png";
import marseilleSoap from "../assets/marseille-soap.png";
import brandPresentation from "../assets/brand.jpg";
import soapCook from "../assets/soap-cook.png";
import soapFlow from "../assets/soap-flow.png";
import soapCut from "../assets/soap-cut.png";
import soapStamp from "../assets/soap-stamp.png";
import soapDry from "../assets/soap-dry.png";
import frenchFlag from "../assets/french.png";
import englishFlag from "../assets/uk.png";
import chineseFlag from "../assets/chinese.png";
import natural from "../assets/natural.png";
import ingredient from "../assets/ingredient.png";
import history from "../assets/history.png";
import soapLemon from "../assets/products/soap_lemon.jpg";
import soapLemon2 from "../assets/products/soap_lemon2.jpg";
import soapPassion from "../assets/products/soap_passion.jpg";
import soapPassion2 from "../assets/products/soap_passion2.jpg";
import soapRaspberry from "../assets/products/soap_raspberry.jpg";
import soapRaspberry2 from "../assets/products/soap_raspberry2.jpg";
import soapVanilla from "../assets/products/soap_vanilla.jpg";
import soapVanilla2 from "../assets/products/soap_vanilla2.jpg";
import soapVerbena from "../assets/products/soap_verbena.jpg";
import soapVerbena2 from "../assets/products/soap_verbena2.jpg";
import soapVine from "../assets/products/soap_vine.jpg";
import soapVine2 from "../assets/products/soap_vine2.png";
import soapVine3 from "../assets/products/soap_vine3.png";
import soapVine4 from "../assets/products/soap_vine4.png";
import soapViolet from "../assets/products/soap_violet.png";
import soapViolet2 from "../assets/products/soap_violet2.png";
import soapViolet3 from "../assets/products/soap_violet3.png";
import soapLavender from "../assets/products/soap_lavender.png";
import soapLavender2 from "../assets/products/soap_lavender2.png";
import soapLavender3 from "../assets/products/soap_lavender3.png";
import soapJasmine from "../assets/products/soap_jasmine.png";
import soapJasmine2 from "../assets/products/soap_jasmine2.png";
import soapJasmine3 from "../assets/products/soap_jasmine3.png";
import soapRose from "../assets/products/soap_rose.png";
import soapRose2 from "../assets/products/soap_rose2.png";
import soapRose3 from "../assets/products/soap_rose3.png";
import soapRose4 from "../assets/products/soap_rose4.png";
import soapTea from "../assets/products/soap_tea.png";
import soapTea2 from "../assets/products/soap_tea2.png";
import soapTea3 from "../assets/products/soap_tea3.png";
import soapMimosa from "../assets/products/soap_mimosa.png";
import soapMimosa2 from "../assets/products/soap_mimosa2.png";
import soapMimosa3 from "../assets/products/soap_mimosa3.png";
import soapApricot from "../assets/products/soap_apricot.png";
import soapApricot2 from "../assets/products/soap_apricot2.png";
import soapWheat from "../assets/products/soap_wheat.png";
import soapLavender200g from "../assets/products/soap_lavender_200g.png";
import soapLavender200g2 from "../assets/products/soap_lavender_200g2.jpg";
import soapOlive100g from "../assets/products/soap_olive_100g.png";
import soapOlive100g2 from "../assets/products/soap_olive_100g2.jpg";
import soapCoco100g from "../assets/products/soap_coco_100g.png";
import soapOlive300g from "../assets/products/soap_olive_300g.jpg";
import soapOlive300g2 from "../assets/products/soap_olive_300g2.jpg";
import soapOlive400g from "../assets/products/soap_olive_400g.png";
import soapOlive400g2 from "../assets/products/soap_olive_400g2.png";
import soapOlive400g3 from "../assets/products/soap_olive_400g3.png";
import soapOlive400g4 from "../assets/products/soap_olive_400g4.png";
import soapOlive400g5 from "../assets/products/soap_olive_400g5.jpg";
import soapOlive600g from "../assets/products/soap_olive_600g.png";
import soapOlive600g2 from "../assets/products/soap_olive_600g2.png";
import soapOlive600g3 from "../assets/products/soap_olive_600g3.png";
import soapOlive600g4 from "../assets/products/soap_olive_600g4.png";
import soapOlive1000g from "../assets/products/soap_olive_1000g.jpg";
import soapOlive1000g2 from "../assets/products/soap_olive_1000g2.jpg";
import iconCube from "../assets/icon_cube.png";
import iconMIF from "../assets/icon_mif.png";
import iconVegan from "../assets/icon_vegan.png";

export const Colors = {
  primary: "#990100",
  secondary: "white",
};

export const Images = {
  logo,
  eiffelTower,
  olive100,
  lavender,
  phiouShop1,
  phiouShop2,
  taobao,
  tmall,
  jd,
  redbook,
  lavenderSoap,
  marseilleSoap,
  brandPresentation,
  parcel,
  france,
  onlineOffline,
  vegan,
  soap,
  soapCook,
  soapCut,
  soapFlow,
  soapDry,
  soapStamp,
  frenchFlag,
  englishFlag,
  chineseFlag,
  natural,
  ingredient, 
  history,
  soapLemon,
  soapPassion,
  soapRaspberry,
  soapVanilla,
  soapVerbena,
  soapVine,
  soapViolet,
  iconCube,
  iconMIF,
  iconVegan,
  soapLavender,
  soapOlive100g,
  soapCoco100g,
  soapOlive300g,
  soapLavender200g,
  soapLemon2,
  soapPassion2,
  soapRaspberry2,
  soapVanilla2,
  soapVerbena2,
  soapVine2,
  soapVine3,
  soapVine4,
  soapViolet2,
  soapViolet3,
  soapRose,
  soapRose2,
  soapRose3,
  soapRose4,
  soapJasmine,
  soapJasmine2,
  soapJasmine3,
  soapTea,
  soapTea2,
  soapTea3,
  soapMimosa,
  soapMimosa2,
  soapMimosa3,
  soapLavender2,
  soapLavender3,
  soapApricot,
  soapApricot2,
  soapWheat,
  soapOlive100g2,
  soapOlive300g2,
  soapOlive400g,
  soapOlive400g2,
  soapOlive400g3,
  soapOlive400g4,
  soapOlive400g5,
  soapOlive600g,
  soapOlive600g2,
  soapOlive600g3,
  soapOlive600g4,
  soapOlive1000g, 
  soapOlive1000g2,
  soapLavender200g2
};
