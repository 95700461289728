export const lavenderSoapIntroFr =
  "Le savon de Provence PHIOU à la lavande est un produit naturellement nourrissant, hydratant et apaisant. Sa composition riche en huile et son parfum envoûtant en font un choix populaire pour prendre soin de la peau et profiter d'un moment de détente lors de la toilette quotidienne.";

export const marseilleSoapIntro1Fr =`
Le savon de Marseille PHIOU à l'huile d'olive est un produit traditionnel emblématique de la ville de Marseille. Il est principalement composé d'huile d'olive. Il est également composé d'eau et de soude, qui sont nécessaires pour la saponification, le processus qui transforme les huiles en savon.

La fabrication est faite selon les méthodes traditionnelles. Elle suit un processus de cuisson lente et de séchage prolongé, qui peut prendre plusieurs semaines, afin d'obtenir un savon de qualité supérieure.

Il est produit en grands cubes ou en pains rectangulaires, puis découpé en morceaux plus petits selon les besoins.`


export const marseilleSoapIntro2Fr = `
Le savon de Marseille à l'huile d'olive est réputé pour ses propriétés douces et nourrissantes pour la peau. L'huile d'olive présente dans sa composition est riche en acides gras essentiels et en vitamines, ce qui aide à hydrater et à protéger la peau. Il convient à tous les types de peau, y compris les peaux sensibles.

Il peut être utilisé pour le lavage du corps, des mains et du visage. Il produit une mousse douce et onctueuse au contact de l'eau, facilitant son application et son rinçage. Il peut également être utilisé comme shampoing.
`;