import { Images } from "../../themes/common";

export const distributorsEn = [
  {
    title: "Taobao",
    image: Images.taobao,
    url: "https://phiou.taobao.com",
  },
  {
    title: "Tmall",
    image: Images.tmall,
    url: "https://phiou.tmall.com",
  },
  {
    title: "JD",
    image: Images.jd,
    url: "",
  },
  {
    title: "Redbook",
    image: Images.redbook,
    url: "https://www.xiaohongshu.com/user/profile/5c071dca0000000007006281",
  },
];