export const soapProductionStepsFr = [
  {
    label: "1. La cuisson",
    description: `On met de l’huile, de la soude, de l’eau et du sel dans le chaudron. On cuit pendant une semaine à 100 - 110 degrés. La cuisson élimine la soude.`,
  },
  {
    label: "2. Le coulage",
    description:
      "Quand il ne reste plus que du savon dans le chaudron on le coule vers un bassin qui se nomme la mise. C’est ici qu’on va couler le savon à l’état liquide. Il va affluer dans le bassin et va se solidifier.",
  },
  {
    label: "3. Le découpage",
    description: `On découpe en gros blocs. Chaque bloc fait environ 50 kilos. Ces blocs on va les réduire en petit cube.`,
  },
  {
    label: "4. Le séchage",
    description: `On fait sécher pendant 4 à 5 jours.`,
  },
  {
    label: "5. L'éstampillage",
    description: `On estampille. A la sortie on doit avoir un cube avec 72% d’huile et qui ne brûle pas la peau. Il faut 21 jours pour fabriquer un cube de savon de Marseille.`,
  },
];

export const soapMarseillaisFr = [
  {
    label: "I. Réaction chimique de saponification",
    description: `La première étape consiste à préparer la pâte à savon en mélangeant les ingrédients de base, Les huiles d’origines végétales sont chauffées dans un grand chaudron. Sous l’action de la soude et de la chaleur, elles se transforment peu à peu en savon.
    `,
  },
  {
    label: "II. Le relargage",
    description:
      "Le savon étant insoluble dans l’eau salée, cette opération consiste à ajouter du sel marin afin d’entraîner la lessive de soude en excès au fond du chaudron, La soude « se relargue » c’est-à-dire qu’elle descend vers le bas de la cuve, le savon restant au-dessus.",
  },
  {
    label: "III. La cuisson",
    description: `Celle-ci permet la complète transformation des huiles végétales en savon par adjonction de lessive de soude concentrée. Cette opération primordiale caractérise la saponification et explique « la longévité proverbiale » du savon de Marseille.`,
  },
  {
    label: "IV. Le lavage",
    description: `La pâte de savon est affinée par des lavages, entraînant le glycérol, les impuretés et les acides gras non saponifiés.`,
  },
  {
    label: "V. La liquidation",
    description: `Un dernier lavage à l’eau claire amène le savon à l’état final. Surnage alors le savon lisse et pur qui fait la réputation du procédé marseillais.`,
  },
  {
    label: "",
    description: "Ces différentes opérations prennent environ plus qu'une semaine."
  },
]