export const brandIntroCn = [`在Phiou，我们相信大自然的力量和其对我们福祉的重要性。这也是为什么我们致力于利用天然成分来制作我们的产品，并优先考虑对环境的保护。`,
`
我们的产品系列包括使用72%植物油制成的马赛皂，为您的肌肤带来奢华滋养的体验。这些马赛皂都是手工制作的，遵循着马赛皂制作的古老传统。通过我们的马赛皂，在可以享受高品质的产品的同时，也能体会到法国工匠们的传统工艺精神。
此外，我们还提供来自马赛的各种香氛马赛皂和普罗旺斯香氛马赛皂，在传统马赛皂的基础上融入了天然的植物成分和舒缓香气。`,
`
我们对马赛皂充满激情的原因之一在于它的多功能性。您可以将其用于身体、面部和头发，简化您的护肤程序。马赛皂不仅温和，还具有抗菌的功效，有助于自然地清洁和净化皮肤。`,
`
马赛皂的另一个优点是其持久性。它永不过期，使其成为可持续发展且经济实惠的选择。通过选择马赛皂，您不仅减少了浪费，还为实践更环保的生活方式做出了贡献。`,
`
我们致力于推广马赛皂的好处，并支持那些致力于保护这一传统的工匠们。马赛皂制造商们在当今市场面临着挑战，希望通过我们的产品来唤起人们对马赛皂及其卓越品质的认识和赞赏。`,
`与Phiou一同启程，发现马赛皂的奇妙之处，体验其滋养和有益的特性。让大自然和传统激发您的自我护理之旅。`];
