import ValueCard from "../UI/ValueCard";
import { Grid } from "@mui/material";
import { Images } from "../../themes/common";

const ValueCards = () => {
  return (
    <Grid container spacing={2} sx={{mb: 4}}>
      <ValueCard
        name="Products sales"
        image={Images.parcel}
        text="brandPage.sales"
      />

      <ValueCard
        name="Natural products"
        image={Images.natural}
        text="brandPage.natural"
      />

      <ValueCard
        name="Online sales"
        image={Images.vegan}
        text="brandPage.vegan"
      />

      <ValueCard
        name="France"
        image={Images.france}
        text="brandPage.marseille"
      />

      <ValueCard
        name="history"
        image={Images.history}
        text="brandPage.history"
      />

      <ValueCard
        name="ingredient"
        image={Images.ingredient}
        text="brandPage.ingredient"
      />
    </Grid>
  );
};

export default ValueCards;
