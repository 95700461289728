import SoapProcess from "../components/Main/SoapProcess";
import MarseilleSoapCard from "../components/Main/MarseilleSoapCard";
import ProvenceSoapCard from "../components/Main/ProvenceSoapCard";
import PhysicShop from "../components/Main/PhysicShop";
import Distributors from "../components/Main/Distributors";
import SoapMarseillais from "../components/Main/SoapMarseillais";
import { useNavigate } from "react-router";
import { useContext } from "react";
import Context from "../context/store";
import { languages } from "../utils/constants";

const HomePage = () => {
  const navigate = useNavigate();
  const {lang} = useContext(Context);
  const {cn} = languages;

  return (
    <>
      <ProvenceSoapCard onClickProvenceSoap={()=> navigate("product/s6-p")}/>
      <MarseilleSoapCard onClickMarseilleSoap={()=> navigate("product/s3-o")} />
      <SoapProcess />
      <SoapMarseillais/>
     { lang === cn && <PhysicShop />}
     {lang === cn && <Distributors />}
    </>
  );
};

export default HomePage;
