import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";

const ProductItem = ({ name, price, images, subTitle, onClickProduct }) => {
  return (
    <Card
      sx={{ maxWidth: 350, minHeight: 520, marginBottom: 5 }}
      onClick={onClickProduct}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="350"
          image={images?.[0]}
          alt="phiou product"
        />
        <CardContent
          style={{
            display: "inline",
          }}
        >
          <Typography gutterBottom variant="h6" component="div" align="center">
            {name}
          </Typography>
          <Typography gutterBottom variant="h6" component="div" align="center">
            {price}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {subTitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductItem;
