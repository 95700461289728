import { useContext, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Language, ExpandMore } from "@mui/icons-material";
import { Images } from "../themes/common";
import { useTranslation } from "react-i18next";
import { languages } from "../utils/constants";
import Context from "../context/store";

export default function HeaderMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const {changeLanguage, lang} = useContext(Context);
  const {fr, en, cn} = languages;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (language) => {
    
    setAnchorEl(null);
    switch (language) {
      case fr:
        return  changeLanguage(fr)
      case en:
        return changeLanguage(en)
      case cn:
        return changeLanguage(cn)
      default:
        return;
    }
  };

  return ( 
    <div style={{position: "absolute", right: 0}}>
      <IconButton
        color="secondary"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Language />
        <Typography style={{marginLeft: 5, marginRight: 3}}> 
          {lang === cn ? '中文': lang === en ? "En":"Fr" }
        </Typography> 
        <ExpandMore/>
      </IconButton>
      
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleClose("fr")}>
          <img
            style={{ height: 14, width: 25, marginRight: 10 }}
            alt="french flag"
            src={Images.frenchFlag}
          />
          {t("header.french")}
        </MenuItem>
        <MenuItem onClick={() => handleClose("en")}>
          <img
            style={{ height: 14, width: 25, marginRight: 10 }}
            alt="english flag"
            src={Images.englishFlag}
          />
          {t("header.english")}
        </MenuItem>
        <MenuItem onClick={() => handleClose("cn")}>
          <img
            style={{ height: 14, width: 25, marginRight: 10 }}
            alt="chinese flag"
            src={Images.chineseFlag}
          />
          {t("header.chinese")}
        </MenuItem>
      </Menu>
    </div>
  );
}
