import { Images } from "../../themes/common";

export const distributorsCn = [
  {
    title: "淘宝",
    image: Images.taobao,
    url: "https://phiou.taobao.com",
  },
  {
    title: "天猫",
    image: Images.tmall,
    url: "https://phiou.tmall.com",
  },
  {
    title: "京东",
    image: Images.jd,
    url: "",
  },
  {
    title: "小红书",
    image: Images.redbook,
    url: "https://www.xiaohongshu.com/user/profile/5c071dca0000000007006281",
  },
];
