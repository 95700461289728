import { useTranslation } from "react-i18next";
import { Grid, Typography, Stack } from "@mui/material";
import DistributorCard from "./DistributorCard";

const Distributors = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography align="center" style={{ fontSize: 24, marginBottom: 30 }}>
        {t("homePage.distributionTitle")}
      </Typography>
      <Grid container item  xs={12} lg={8} style={{justifyContent: 'center'}}>
      <Stack
        direction="row"
        spacing={3}
        justifyContent="center"
        style={styles.partnerContainer}
      >
        {JSON.parse(t("homePage.partnerList")).map((distributor) => {
          return (
            <DistributorCard
              title={distributor.title}
              image={distributor.image}
              url={distributor.url}
            />
          );
        })}
      </Stack>
      </Grid>
    </>
  );
};

const styles = {
  partnerContainer: {
    marginBottom: 20,
  },
};

export default Distributors;
