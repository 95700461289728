import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const Title = () => {
  const { t } = useTranslation();
  return (
  <>
    <Typography sx={{ fontSize: 30 }} align="center" gutterBottom>
      <h4>{t("brandPage.title")}</h4>
    </Typography>
    <Typography sx={{ fontSize: 18 }}gutterBottom>
    {JSON.parse(t("brandPage.brandIntro")).map((item)=><p>{item}</p>)}
    </Typography>
  </>
  );
};

export default Title;
