import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Images } from "../../themes/common";
import { useTranslation } from "react-i18next";

const MarseilleSoapCard = ({onClickMarseilleSoap}) => {
  const { t } = useTranslation();

  return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
            <Typography gutterBottom variant="h4" textAlign={"center"} sx={{mt: 2}}>
              {t("homePage.marseilleSoapTitle")}
            </Typography>
            <Typography variant="h5" color="text.primary" textAlign={"justify"}>
              {t("homePage.marseilleSoapIntro1")}
            </Typography>
            <div style={styles.cttSubContainer}>
              <Typography gutterBottom variant="h4" textAlign={"center"}>
                {t("homePage.marseilleSoapSubTitle")}
              </Typography>
              <Typography
                variant="h6"
                textAlign={"justify"}
              >
                {t("homePage.marseilleSoapIntro2")}
              </Typography>
              <CardActions style={styles.actionContainer}>
                <Button size="small" variant="contained" onClick={onClickMarseilleSoap}>
                  {t("homePage.knowMore")}
                </Button>
              </CardActions>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <CardMedia
            component="img"
            alt="marseille soap"
            sx={{
              alignSelf: "flex-end",
            }}
            image={Images.marseilleSoap}
          />
        </Grid>
      </Grid>
  );
};

const styles = {
  cttContainer: {
    // backgroundColor: "darkgreen",
  },
  cttSubContainer: {
    backgroundColor: "green",
    borderRadius: "20px",
    marginTop: 20,
    padding: 20,
  },
  actionContainer: {
    marginTop: 10,
  },
};
export default MarseilleSoapCard;
