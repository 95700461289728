export const brandIntroEn = [`At Phiou, we believe in the power of nature and its importance for our well-being. That's why we have carefully crafted our products to harness the goodness of natural ingredients while prioritizing the preservation of our environment.`,

`Our range includes Marseille soaps made with 72% olive oil, ensuring a luxurious and nourishing experience for your skin. These soaps are crafted by hand, following the age-old tradition of Marseille soap making. Through our Marseille soap, while enjoying high-quality products, you can also experience the traditional craft spirit of French craftsmen.`,

`In addition, we offer a wide range of scented Marseille soaps and Provence scented Marseille soaps from Marseille, based on traditional Marseille soaps, infused with natural botanical ingredients and soothing aromas.`,

`One of the key reasons we are passionate about Marseille soap is its versatility. You can use it for your body, face, and hair, simplifying your skincare routine. Marseille soap is not only gentle but also an effective antiseptic, helping to cleanse and purify your skin naturally.`,

`Another advantage of Marseille soap is its longevity. It never expires, making it a sustainable and cost-effective choice. By choosing Marseille soap, you reduce waste and contribute to a more eco-friendly lifestyle.`,

`We are dedicated to promoting the benefits of Marseille soap and supporting the artisans who keep this tradition alive. Manufacturers of Marseille soap are challenged in today's market, we hope to build awareness and appreciation of Marseille soap and its exceptional quality through our products.`,

`Set off with Phiou to discover the wonders of Marseille soap and experience its nourishing and beneficial properties. Let nature and tradition inspire your self-care journey.`];