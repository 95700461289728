import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SoapMarseillais = () => {
  const { t } = useTranslation();

  return (

    <Grid
      container
      direction={"column"}
      wrap="nowrap"
      spacing={2}
      sx={{my: 4}}
    >
      <Grid item xs={12} lg={8}>
        <Typography
          gutterBottom
          variant="h4"
          style={{ marginBottom: 20 }}
        >
          {t("homePage.soapMarseillais")}
        </Typography>      
      </Grid>
      <Grid item xs={12} lg={8}>
      {JSON.parse(t("homePage.soapMarseillaisDetails")).map((process)=> 
      <>
      <Typography variant="h5"  >
        {process.label}
      </Typography>
      <Typography style={{ marginBottom: 10}}>
        {process.description}
      </Typography>
      </>
      )}
      </Grid>
    </Grid>
  );
};

export default SoapMarseillais;
