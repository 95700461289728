import { Stack, CardMedia } from "@mui/material";
import { Images } from "../../themes/common";

const Image = () => {
  return (
    <Stack sx={{ flexGrow: 1, overflow: "hidden" }} alignItems="center">
      <CardMedia
        component="img"
        alt="Brand Phiou presentation"
        height="100%"
        sx={{
          marginTop: 5,
        }}
        image={Images.brandPresentation}
      />
    </Stack>
  );
};

export default Image;
